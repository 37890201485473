import React from 'react';
import styled from 'styled-components';
import { Check } from 'react-feather';

function Checklist({ checkColor, items }) {
  return (
    <List>
      {items
        .filter((item) => !!item)
        .map((item, index) => (
          <li key={index}>
            <CheckIcon size={20} style={{ '--color': checkColor }} />
            {item}
          </li>
        ))}
    </List>
  );
}

const List = styled.ul`
  li {
    display: flex;
    margin-bottom: 8px;
  }
`;

const CheckIcon = styled(Check)`
  color: var(--color, var(--color-success));
  margin-right: 8px;
  transform: translateY(3px);
  flex-shrink: 0;

  polyline {
    stroke-width: 3px;
  }
`;

export default Checklist;
