import React from "react";
import styled from "styled-components";

function TripleHills({ hue = 34, ...delegated }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      fill="none"
      viewBox="0 0 4245 782"
      {...delegated}
    >
      <path
        fill={`hsl(${hue}deg 16% 6% / 0.375)`}
        d="M969.169 143.762C576.431 160.705 0 283.153 0 283.153V782h4245V513.94s-345.21-261.944-615.88-434.513c-253.06-161.346-425.84-32.244-632.41 0-200.72 31.33-270.74 174.553-463.04 240.999-572.84 197.939-960.16-202.736-1564.501-176.664z"
      ></path>
      <path
        fill={`hsl(${hue}deg 26% 10% / 0.85)`}
        d="M909.204 493C550.67 500.797 0 367 0 367v415h4245V571.501S3686.78 180.733 3357.3 288C2888.87 440.501 2830 104 2232.57 104c-182.11 0-240.64 126.478-398.29 215.5-318.47 179.829-557.3 165.502-925.076 173.5z"
      ></path>
      <path
        fill={`hsl(${hue}deg 16% 6% / 1)`}
        d="M690.346 332.294C380.446 419.805 0 782 0 782h4245V648.939s-725.51-127.023-1191.41-97.544c-382.45 24.198-581.76 178.332-964.98 177.581-567.76-1.113-852.02-550.933-1398.264-396.682z"
      ></path>
    </Svg>
  );
}

const Svg = styled.svg`
  display: block;
`;

export default TripleHills;
