import React from 'react';
import styled from 'styled-components';

import { detectBrowser } from '@helpers/browser.helpers';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import Image from '@components/Image';

const VersionOnePointOne = () => {
  const [browser, setBrowser] = React.useState(null);

  React.useEffect(() => {
    setBrowser(detectBrowser());
  }, []);

  return (
    <MaxWidthWrapper>
      <BoxImage src="/illustrations/01-box-new.png" width={385 / 2} />
      <PseudoTitle>New and Improved!</PseudoTitle>
      <Paragraph className={browser !== 'safari' && 'not-safari'}>
        Since the course launched in 2021, I've received{' '}
        <strong>over 2700 pieces of feedback</strong> from students,
        and have made <em>hundreds</em> of improvements based on these
        suggestions. There is so much new and re-worked content.
      </Paragraph>
      <Paragraph>
        The end result is a smoother learning experience with less
        confusion and improved results. There's never been a better
        time to get started. 💖
      </Paragraph>
    </MaxWidthWrapper>
  );
};

const BoxImage = styled(Image)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -48px;
  transform: translateX(32px);
`;

const PseudoTitle = styled.p`
  position: relative;
  display: block;
  font-style: normal;
  font-size: min(48px, 9vw);
  font-weight: 500;
  text-align: center;
  background-image: linear-gradient(
    200.67deg,
    #ffffff 25%,
    hsl(54deg 100% 70%) 35%,
    hsl(36deg 100% 55%) 55.54%,
    hsl(333deg 100% 50%) 75%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 24px;
`;

const Paragraph = styled.p`
  font-size: 1.25rem;
  text-align: left;

  & strong {
    color: hsl(45deg 100% 65%);
  }

  &.not-safari strong {
    background-color: #ffb23e;
    background-image: linear-gradient(
      182deg,
      #ffffff 15%,
      hsl(54deg 100% 70%),
      hsl(36deg 100% 55%)
    );
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
`;

export default VersionOnePointOne;
