const TEST_GROUP_KEY = 'is-in-test-group';

export function getTestGroupStatus() {
  // 2022/01/19: End the Email Opt-in test, since it isn't
  // converting as well!
  return false;

  let persistedValue = window.localStorage.getItem(TEST_GROUP_KEY);

  if (persistedValue) {
    // Turn a 'false' string into false boolean
    return JSON.parse(persistedValue);
  } else {
    // A/B test: 50/50 chance of being in either group
    const isInTestGroup = false;

    window.localStorage.setItem(TEST_GROUP_KEY, isInTestGroup);
    return isInTestGroup;
  }
}
