import React from 'react';
import styled from 'styled-components';

import { TopCurve, BottomCurve } from './TestimonialWrapper.curves';

const TestimonialWrapper = ({ children }) => {
  return (
    <Wrapper>
      <TopCurveWrapper>
        <TopCurve />
      </TopCurveWrapper>
      <Backdrop>
        <SkewShifter>{children}</SkewShifter>
      </Backdrop>
      <BottomCurveWrapper>
        <BottomCurve />
      </BottomCurveWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  z-index: 2;
`;

const CurveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  /* Truncate curve if it leaves the region */
  overflow: hidden;
`;
const TopCurveWrapper = styled(CurveWrapper)`
  justify-content: flex-end;
  margin-top: -40px;
  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 100%, 0) 0%,
    hsla(0, 0%, 100%, 0.002) 8.1%,
    hsla(0, 0%, 100%, 0.007) 15.5%,
    hsla(0, 0%, 100%, 0.016) 22.5%,
    hsla(0, 0%, 100%, 0.026) 29%,
    hsla(0, 0%, 100%, 0.039) 35.3%,
    hsla(0, 0%, 100%, 0.053) 41.2%,
    hsla(0, 0%, 100%, 0.068) 47.1%,
    hsla(0, 0%, 100%, 0.082) 52.9%,
    hsla(0, 0%, 100%, 0.097) 58.8%,
    hsla(0, 0%, 100%, 0.111) 64.7%,
    hsla(0, 0%, 100%, 0.124) 71%,
    hsla(0, 0%, 100%, 0.134) 77.5%,
    hsla(0, 0%, 100%, 0.143) 84.5%,
    hsla(0, 0%, 100%, 0.148) 91.9%,
    hsla(0, 0%, 100%, 0.15) 100%
  );
  transform: translateY(1px);
`;
const BottomCurveWrapper = styled(CurveWrapper)`
  margin-bottom: -40px;
  background: linear-gradient(
    to top,
    hsla(0, 0%, 100%, 0) 0%,
    hsla(0, 0%, 100%, 0.002) 8.1%,
    hsla(0, 0%, 100%, 0.007) 15.5%,
    hsla(0, 0%, 100%, 0.016) 22.5%,
    hsla(0, 0%, 100%, 0.026) 29%,
    hsla(0, 0%, 100%, 0.039) 35.3%,
    hsla(0, 0%, 100%, 0.053) 41.2%,
    hsla(0, 0%, 100%, 0.068) 47.1%,
    hsla(0, 0%, 100%, 0.082) 52.9%,
    hsla(0, 0%, 100%, 0.097) 58.8%,
    hsla(0, 0%, 100%, 0.111) 64.7%,
    hsla(0, 0%, 100%, 0.124) 71%,
    hsla(0, 0%, 100%, 0.134) 77.5%,
    hsla(0, 0%, 100%, 0.143) 84.5%,
    hsla(0, 0%, 100%, 0.148) 91.9%,
    hsla(0, 0%, 100%, 0.15) 100%
  );
  transform: translateY(-1px);
`;

const Backdrop = styled.div`
  background: var(--color-background);
`;

const SkewShifter = styled.div`
  padding-top: 2vw;
  padding-bottom: 4vw;
`;

export default TestimonialWrapper;
