import React from 'react';
import styled, { keyframes } from 'styled-components';

import { detectBrowser } from '@helpers/browser.helpers';

function CurvedPreHeading({ hasLaunched, children, ...delegated }) {
  const [browser, setBrowser] = React.useState(null);

  React.useEffect(() => {
    setBrowser(detectBrowser());
  }, []);

  return (
    <Svg
      viewBox="0 0 500 125"
      hasLaunched={hasLaunched}
      fill="none"
      includeWipeAnimation={browser && browser !== 'safari'}
      {...delegated}
    >
      <path
        id="title-curve"
        d="M20,125 Q250,25 480,125"
        fill="transparent"
      />
      <text x={4} width="500">
        <textPath xlinkHref="#title-curve">{children}</textPath>
      </text>
    </Svg>
  );
}

const wipeIn = keyframes`
  from {
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
`;

const goldToGray = keyframes`
  from {
    fill: hsl(38deg 100% 55%);
  }
  to {
    fill: var(--color-gray-700);
  }
`;

const Svg = styled.svg`
  display: block;
  width: 100%;
  max-width: 420px;
  overflow: visible;
  animation: ${(p) => (p.includeWipeAnimation ? wipeIn : fadeIn)}
    2000ms 300ms both;
  will-change: transform;

  text {
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin: 0;
    fill: hsl(38deg 100% 55%);
    animation: ${goldToGray} 2500ms 1300ms both;
  }
`;

export default CurvedPreHeading;
