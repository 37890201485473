import React from 'react';
import styled from 'styled-components';

import TestimonialWrapper from '../TestimonialWrapper';

const DoubleTestimonial = ({ children }) => {
  const [first, second] = children;
  return (
    <TestimonialWrapper>
      <Row>
        <Col>{first}</Col>
        <Col>{second}</Col>
      </Row>
    </TestimonialWrapper>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 64px;
  }
`;

const Col = styled.div`
  flex: 1;
  margin-bottom: 64px;
  max-width: 520px;

  @media (min-width: 900px) {
    margin-bottom: 0px;

    &:first-child {
      margin-right: 64px;
    }
  }

  @media (min-width: 1200px) {
    &:first-child {
      margin-right: 128px;
    }
  }
`;

export default DoubleTestimonial;
