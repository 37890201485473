import React from 'react';
import styled from 'styled-components';

import { random } from '@utils';
import Spinner from '@components/Spinner';
import Sparkles from '@components/Sparkles';

const generateSparklePosition = (size) => {
  let style = {};
  style.left = random(0, 100) + '%';
  style.top = random(16, 80) + '%';
  style.zIndex = 3;

  return style;
};

function PriceDisplay({
  emphasized,
  pricingInfo,
  enableRegionalDiscount,
}) {
  const hasFetchedPrice = typeof pricingInfo.salePrice === 'number';

  if (!hasFetchedPrice) {
    return (
      <LoadingPrice>
        <Spinner color="#333" />
      </LoadingPrice>
    );
  }

  let crossedOutPrice = pricingInfo.fullPrice;
  let actualPrice = enableRegionalDiscount
    ? pricingInfo.regionalLicensePrice
    : pricingInfo.salePrice;

  // If Paddle bumps up the price before the front-end has been
  // updated, we might accidentaly show the current price crossed
  // out. Check if these two numbers are the same!
  const showCrossedOutPrice =
    crossedOutPrice && crossedOutPrice !== actualPrice;

  const ActualPriceComponent = emphasized
    ? EmphasizedPrice
    : ActualPrice;

  const roundedActualPrice = Math.ceil(actualPrice);

  return (
    <PriceWrapper>
      {showCrossedOutPrice && (
        <FullPrice aria-hidden="true">
          <Dollar>$</Dollar>
          {crossedOutPrice}
        </FullPrice>
      )}
      <ActualPriceComponent>
        <Dollar>$</Dollar>
        {emphasized ? (
          <Sparkles
            rate={1000}
            variance={500}
            minSize={30}
            maxSize={50}
            generatePosition={generateSparklePosition}
          >
            {roundedActualPrice}
          </Sparkles>
        ) : (
          roundedActualPrice
        )}
      </ActualPriceComponent>
    </PriceWrapper>
  );
}

const PriceWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
`;

const LoadingPrice = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
`;

const Dollar = styled.span`
  display: inline-block;
  font-size: 0.65em;
  transform: translateY(-30%);
`;

const FullPrice = styled.div`
  color: var(--color-gray-700);
  position: relative;
  font-size: 1.75rem;
  font-weight: 300;
  margin-right: 16px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 3px;
    background-color: hsl(345deg 100% 50% / 0.8);
    transform: rotate(-15deg);
    border-radius: 4px;
  }
`;

const ActualPrice = styled.div`
  font-size: 2.5rem;
  font-weight: 500;
`;

const EmphasizedPrice = styled.div`
  font-size: 3.5rem;
  font-weight: 500;
`;

export default PriceDisplay;
