import React from 'react';
import styled from 'styled-components';
import {
  X,
  Info,
  AlertTriangle,
  AlertOctagon,
  CheckCircle,
} from 'react-feather';
import { useSpring, animated } from 'react-spring';

import usePrefersReducedMotion from '@hooks/use-prefers-reduced-motion.hook';

import Boop from '@components/Boop';
import Paper from '@components/Paper';
import UnstyledButton from '@components/UnstyledButton';
import Spacer from '@components/Spacer';
import VisuallyHidden from '@components/VisuallyHidden';
import InPortal from '@components/InPortal';

const ICONS_BY_TYPE = {
  info: Info,
  success: CheckCircle,
  warning: AlertTriangle,
  error: AlertOctagon,
};

const Toast = ({
  type,
  title,
  handleDismiss,
  popTimeout = 400,
  children,
  ...delegated
}) => {
  const Icon = ICONS_BY_TYPE[type];

  const prefersReducedMotion = usePrefersReducedMotion();

  const [popped, setPopped] = React.useState(false);

  React.useEffect(() => {
    const timeoutId = window.setTimeout(
      () => setPopped(true),
      popTimeout
    );

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, []);

  const style = useSpring({
    from: { transform: 'translateY(150%)' },
    to: {
      transform: popped ? 'translateY(0px)' : 'translateY(150%)',
    },
    config: {
      tension: 350,
      friction: 20,
      mass: 2,
      precision: 0.001,
    },
    immediate: prefersReducedMotion,
  });

  const hasPoppedAlready = React.useRef(false);
  React.useEffect(() => {
    let timeoutId;
    if (popped) {
      hasPoppedAlready.current = true;
    } else if (hasPoppedAlready.current) {
      timeoutId = window.setTimeout(handleDismiss, 500);
    }

    return () => window.clearTimeout(timeoutId);
  }, [popped]);

  return (
    <InPortal>
      <OuterWrapper
        {...delegated}
        data-test="toast"
        data-test-type={type}
        data-test-title={title}
        data-test-body={children}
      >
        <Wrapper
          as={animated.div}
          style={{
            '--main-color': `var(--color-${type})`,
            ...style,
          }}
        >
          <Icon size={24} style={{ color: `var(--main-color)` }} />
          <Spacer $size={16} />
          <Main>
            <Heading>{title}</Heading>
            <p>{children}</p>
          </Main>
          <Spacer $size={16} />
          <Boop rotation={10}>
            <CloseButton onClick={() => setPopped(false)}>
              <X />
              <VisuallyHidden>Dismiss Toast</VisuallyHidden>
            </CloseButton>
          </Boop>
        </Wrapper>
      </OuterWrapper>
    </InPortal>
  );
};

const OuterWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  padding: 16px;
  display: flex;
  justify-content: center;

  @media print {
    display: none;
  }
`;

const CloseButton = styled(UnstyledButton)`
  color: var(--color-gray-500);

  &:hover {
    color: black;
  }
`;

const Wrapper = styled(Paper)`
  display: flex;
  align-items: center;
  box-shadow: var(--shadow-elevation-high);
  border-radius: 4px;
  padding: 16px;
  border-left: 4px solid var(--main-color);
  max-width: 600px;
  pointer-events: auto;
  will-change: transform;

  svg {
    display: block !important;
  }
`;

const Main = styled.div`
  flex: 1;

  p {
    margin-bottom: 0;
    font-size: 0.925rem;
    color: var(--color-gray-700);
  }
`;

const Heading = styled.h2`
  font-weight: var(--font-weight-medium);
  font-size: 1rem;
  margin-bottom: 4px;
`;

export default Toast;
