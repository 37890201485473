import React from 'react';
import styled from 'styled-components';

const MODULES = [
  'Fundamentals',
  'Rendering Logic I',
  'Rendering Logic II',
  'Modern Component Architecture',
  'Flexbox',
  'Responsive and Functional CSS',
  'Typography and Images',
  'CSS Grid',
  'Animation and Interaction',
  'Little Big Details',
];

function IncludedModules({ num }) {
  const renderedModules = MODULES.slice(0, num);

  return (
    <Wrapper>
      <Title>Includes</Title>
      <List>
        {renderedModules.map((title, index) => {
          return (
            <Module key={index}>
              <Num>{index}</Num> {title}
            </Module>
          );
        })}
      </List>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: hsl(0deg 0% 0% / 0.1);
  padding: 16px;
  margin: 0 -16px -16px;
`;

const Title = styled.h3`
  font-size: 1rem;
  margin-bottom: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: var(--font-weight-medium);
  font-size: 1.125rem;
  opacity: 0.71;
`;

const List = styled.ol`
  display: flex;
  flex-direction: column;
`;

const Module = styled.li`
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-bold);

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

const Num = styled.span`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: hsl(0deg 0% 25%);
  color: white;
  border-radius: 50%;
  display: grid;
  place-content: center;
  margin-right: 16px;
`;

export default IncludedModules;
