import React from "react";
import styled from "styled-components";
import { Info } from "react-feather";

import Tooltip from "@components/Tooltip";

function InfoTooltip({ children }) {
  return (
    <Tooltip content={children}>
      <Icon size={14} />
    </Tooltip>
  );
}

const Icon = styled(Info)`
  color: hsl(0deg 0% 35%);
  transform: translateY(2px);
`;

export default InfoTooltip;
