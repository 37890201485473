import React from 'react';
import styled from 'styled-components';
import { AlertOctagon } from 'react-feather';

function Error() {
  return (
    <Wrapper>
      <IconWrapper>
        <AlertOctagon size={64} />
      </IconWrapper>
      <h2>Could not fetch prices</h2>
      <p>My merchant, Paddle, isn't cooperating.</p>
      <p>
        If you're using a VPN, please disable it and try again. If the
        problem persists, please{' '}
        <a href="mailto:support@joshwcomeau.com">shoot me an email</a>{' '}
        and I'll be glad to help. If there's any info in the console,
        please send that along as well.
      </p>
      <p>Sorry about that!</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 650px;
  margin: 0 auto;
  padding: 32px;
  border: 5px dashed hsl(274deg 10% 25%);
  border-radius: 16px;
  text-align: center;

  h2 {
    font-size: 48px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 72px;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 2em;
  }
`;

const IconWrapper = styled.div`
  text-align: center;
  margin-bottom: 16px;
  color: hsl(274deg 10% 25%);
`;

export default Error;
