import React from "react";
import "video.js/dist/video-js.css";

const LazyVideoPlayer = (props) => {
  const [Component, setComponent] = React.useState(() => () => null);

  React.useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      import("./VideoPlayer.js").then((Thing) =>
        setComponent(() => Thing.default)
      );
    }, 1500);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, []);

  return <Component {...props} />;
};

export default LazyVideoPlayer;
