import React from 'react';

const Image = ({ src, extension = 'png', ...delegated }) => {
  return (
    <picture>
      <source
        srcSet={src.replace(`.${extension}`, '.avif')}
        type="image/avif"
      />
      <img src={src} {...delegated} />
    </picture>
  );
};

export default Image;
