import React from "react";
import styled from "styled-components";

import { DATA } from "./Curriculum.constants";
import ModuleCard from "./ModuleCard";

function CardGrid() {
  return (
    <Wrapper>
      {DATA.map((item, index) => (
        <ModuleCard
          key={item.number}
          number={item.number}
          title={item.title}
          description={item.description}
          bullets={item.bullets}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 32px;
  padding: 32px;
  display: none;
  margin-bottom: 64px;

  @media (max-width: 1024px) {
    display: grid;
  }
`;

export default CardGrid;
