import React from 'react';
import styled, { keyframes } from 'styled-components';

import { getFormattedTimeRemaining } from '@helpers/time.helpers';

import DesktopOnly from '@components/DesktopOnly';
import Sparkles from '@components/Sparkles';

import { SUPERHEADER_HEIGHT } from './Header.constants';

const Superheader = ({ saleEnds, timeRemaining, isTwilightHour }) => {
  return (
    <Wrapper>
      <p>
        <Sparkles
          isToggleable={false}
          stopAfter={6000}
          rate={500}
          variance={400}
          colors={['#FFF']}
        >
          <strong>
            Black Friday event{isTwilightHour && ' extended'}!
          </strong>
        </Sparkles>{' '}
        Ends {getFormattedTimeRemaining(timeRemaining)}
      </p>
      <Zigzag />
    </Wrapper>
  );
};

const slideIn = keyframes`
  from {
    transform: translateY(-${SUPERHEADER_HEIGHT});
  }

  to {
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  background: black;
  height: ${SUPERHEADER_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${slideIn} 500ms both cubic-bezier(0.28, -0.03, 0.11, 1);
  will-change: transform;
  backface-visibility: hidden;

  p {
    margin: 0;
    font-size: 1.125rem;
    text-align: center;
    animation: ${fadeIn} 500ms both;
    animation-delay: 200ms;
  }

  strong {
    font-weight: var(--font-weight-bold);
    background-color: #ffb23e;
    background-image: linear-gradient(
      182deg,
      #ffffff 15%,
      hsl(54deg 100% 70%),
      hsl(36deg 100% 55%)
    );
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
`;

const Zigzag = styled.div`
  --size: 8px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--size);
  transform: translateY(100%);
  background-image: url('/zigzag.svg');
  background-repeat: repeat;
  background-size: calc(var(--size) * (12 / 6));
`;

export default Superheader;
