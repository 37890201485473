import React from 'react';
import styled from 'styled-components';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import ClientOnly from '@components/ClientOnly';
import Image from '@components/Image';

import PhotoEffect from './PhotoEffect';

const AboutMe = () => {
  return (
    <Wrapper maxWidth={900} as="section">
      <Header>
        <ClientOnly>
          <PhotoEffect />
        </ClientOnly>
        <Heading>Hi, I'm Josh!</Heading>
      </Header>
      <MainContent>
        <p>
          Believe it or not, the very first non-trivial web
          application I built was an online education platform! My
          friend worked as a tutor, and he wanted a way to assign math
          problems to his students. I built it with PHP, MySQL, and
          jQuery. It was a dog's breakfast, but it worked.
        </p>
        <p>
          I abandoned that project when I discovered{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.khanacademy.org/"
          >
            Khan Academy
          </a>
          , a world-renowned non-profit on a mission to provide a
          free, world-class education online. Years later, I would
          join as a software engineer, to help build that platform! I
          got to work alongside some of the smartest pedagogical minds
          in the world, and I learned <em>a whole lot</em> about how
          to create effective educational content.
        </p>
        <p>
          I've spent the last couple years working in senior
          engineering roles at organizations like{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.digitalocean.com/"
          >
            DigitalOcean
          </a>{' '}
          and{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.gatsbyjs.com/"
          >
            Gatsby Inc.
          </a>
          , building tools to help developers get their ideas off the
          ground.
        </p>
        <p>
          For the past few years, I've also taught part-time for{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.journeyedu.co/"
          >
            Journey Education
          </a>
          , developing curriculum and leading web-development courses
          at{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://concordiabootcamps.ca/"
          >
            Concordia University
          </a>
          .
        </p>
        <p>
          It's been almost a decade since I built my first educational
          platform. I've learned a lot about education since then, and
          it's high time I do something with that knowledge and
          experience.
        </p>
        <p>
          <em>CSS for JavaScript Developers</em> is the culmination of
          my career, and I genuinely believe it'll be profoundly
          useful.{' '}
          <strong>
            I'm so excited to help you on your journey with CSS
          </strong>
          !
        </p>
        <Footer>
          <Signature src="/signature.png" alt="" />
          <SignatureInEnglish>
            <strong>Josh W. Comeau</strong>
            <br />
            Instructor, <em>CSS for JavaScript Developers</em>
          </SignatureInEnglish>
        </Footer>
      </MainContent>
    </Wrapper>
  );
};

const Wrapper = styled(MaxWidthWrapper)`
  isolation: isolate;

  a {
    /*
      Arg. For some reason, gradient text on an inline element looks
      all funky on Safari. These links are all pretty short, so
      let's do inline-block to solve the problem.
    */
    display: inline-block;

    &:not(:hover) {
      background-color: hsl(250deg 100% 77.5%);
      background-image: linear-gradient(
        190deg,
        hsl(280deg 100% 80%) 20%,
        hsl(225deg 100% 75%) 80%
      );
      background-size: 100%;
      background-clip: text;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }

    &:hover {
      color: hsl(265deg 100% 80%);
    }
  }
`;

const Header = styled.header`
  position: relative;
`;

const Heading = styled.h2`
  font-size: min(96px, 15vw);
  opacity: 1;
  position: absolute;
  bottom: 16px;
  /* Optical alignment */
  left: -5px;
  /* prettier-ignore */
  text-shadow:
    0px 0px 30px hsl(274deg 16% 8% / 0.5),
    0px 5px 15px hsl(274deg 16% 8% / 0.5),
    0px 1px 2px hsl(274deg 16% 8% / 0.5);

  @media (max-width: 500px) {
    bottom: 48px;
  }
`;

const MainContent = styled.div`
  padding-top: 72px;
  max-width: 65ch;
  margin: 0 auto;

  p {
    font-size: 21px;
  }
`;

const Footer = styled.div`
  position: relative;
  width: 300px;
  margin-top: 72px;

  @media (max-width: 500px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Signature = styled(Image)`
  display: block;
  width: 300px;
  height: 101px;
  filter: invert(100%);
`;

const SignatureInEnglish = styled.div`
  margin-top: 8px;
  text-align: center;
  font-size: 14px;

  strong {
    font-size: 18px;
  }
`;

export default AboutMe;
