import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';

import { detectBrowser } from '@helpers/browser.helpers';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import Sparkles from '@components/Sparkles';
import SignupForm from '@components/SignupForm';

const LastChanceSignupForm = ({ handleSignup }) => {
  const [browser, setBrowser] = React.useState(null);

  React.useEffect(() => {
    setBrowser(detectBrowser());
  }, []);

  return (
    <Wrapper maxWidth={700}>
      <Heading>
        Check out a{' '}
        <Sparkles
          isToggleable={false}
          colors={['#FFF']}
          minSize={25}
          maxSize={45}
          rate={1250}
          variance={500}
        >
          <GradientText
            className={browser !== 'safari' && 'not-safari'}
          >
            Free Preview
          </GradientText>
        </Sparkles>
        !
      </Heading>
      <Paragraph>
        Sign up to receive a sneak peek of the course. Over the next
        week, I'll teach you some of the most important things I've
        learned about CSS.
      </Paragraph>
      <Paragraph>
        <strong>Enter your email address:</strong>
      </Paragraph>
      <SignupForm handleSuccess={handleSignup} />
    </Wrapper>
  );
};

const Wrapper = styled(MaxWidthWrapper)`
  margin-top: 128px;
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  font-weight: var(--font-weight-bold);
  margin-bottom: 24px;
  text-align: center;

  @media (min-width: 400px) {
    font-size: 2rem;
  }
  @media (min-width: 685px) {
    font-size: 3rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1.25rem;
  max-width: 45ch;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    text-align: left;
  }
`;

const GradientText = styled.span`
  color: hsl(45deg 100% 65%);

  &.not-safari {
    background-color: #ffb23e;
    background-image: linear-gradient(
      182deg,
      #ffffff 15%,
      hsl(54deg 100% 70%),
      hsl(36deg 100% 55%)
    );
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
`;

export default LastChanceSignupForm;
