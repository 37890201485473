import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

import useBoop from '@hooks/use-boop.hook';

const Boop = ({
  x,
  y,
  rotation,
  scale,
  timing,
  children,
  springConfig,
  style = {},
  ...delegated
}) => {
  const [boopStyle, trigger] = useBoop({
    x,
    y,
    rotation,
    scale,
    timing,
    springConfig,
  });

  return (
    <Wrapper
      onMouseEnter={trigger}
      style={{ ...style, ...boopStyle }}
      {...delegated}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(animated.span)`
  display: inline-block;
  will-change: transform;
`;

export default Boop;
