import React from "react";
import styled from "styled-components";

export const SingleSwoop = ({ color }) => {
  return (
    <svg
      width="100%"
      height={600}
      viewBox="0 0 1200 600"
      preserveAspectRatio="none"
      style={{
        display: "block",
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 0,150 C 400,0 800,400 1200,150 L 1200,600 L 0,600 Z"
        fill={color}
      ></path>
    </svg>
  );
};

export const DoubleSwoop = ({ color }) => {
  return (
    <Clamp>
      <svg
        width="100%"
        height={300}
        viewBox="0 0 1200 400"
        preserveAspectRatio="none"
        style={{
          display: "block",
          minWidth: 600,
        }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 0,50 C 250,400 900,-200 1200,200 L 1200,400 L 0,400 Z"
          fill={color}
        ></path>
      </svg>
    </Clamp>
  );
};

export const ThirdSwoop = ({ color }) => {
  return (
    <svg
      width="100%"
      height={150}
      viewBox="0 0 1200 200"
      preserveAspectRatio="none"
      style={{
        display: "block",
        minWidth: 600,
        overflow: "visible",
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 0,125 C 500,-100 900,200 1200,50 L 1200,400 L 0,400 Z"
        fill={color}
      ></path>
    </svg>
  );
};

export const FourthSwoop = ({ color }) => {
  return (
    <svg
      width="100%"
      height={150}
      viewBox="0 0 1200 200"
      preserveAspectRatio="none"
      style={{
        display: "block",
        minWidth: 600,
        overflow: "visible",
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M -120,200 C 450,-150 800,350 1200,-50 L 1200,400 L 0,400 Z"
        fill={color}
      ></path>
    </svg>
  );
};

export const HeroSwoop = ({
  color = "var(--color-background)",
  ...delegated
}) => {
  return (
    <svg
      width="100%"
      height="303"
      viewBox="0 0 1440 303"
      fill="none"
      preserveAspectRatio="none"
      style={{
        display: "block",
        minWidth: 600,
        overflow: "visible",
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <path
        d="M339 122C220.647 106.212 176.609 -12.2323 58 1.50005C29.0671 4.84984 -14.5 19.0001 -14.5 19.0001V303H1443V184C1443 184 1296.24 217.673 1200.5 221C974.265 228.862 864.366 79.4709 638 81.0001C520.143 81.7962 455.825 137.584 339 122Z"
        fill={color}
      />
    </svg>
  );
};

export function GroundSwoop({ width, color = "black", ...delegated }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 2839 579"
      width={width}
      {...delegated}
    >
      <g clipPath="url(#clip0)">
        <path
          fill={color}
          d="M2095 482c344.77-31.423 744-482 744-482v578.5H-127s0-214 331-256C392.489 298.583 452.227 451.156 625.5 482c332.323 59.155 508.95-108.5 846.5-108.5 237.5 0 374.02 131.192 623 108.5z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H2839V579H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export function GroundSwoopB({ width, color = "black", ...delegated }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      fill="none"
      viewBox="0 0 2986 393"
      {...delegated}
    >
      <path
        fill={color}
        d="M573.5 94C346.756 91.146.5 0 .5 0v393h2985V222s-334.14-75.852-530.5-94c-222.28-20.544-346.81 47.19-570 43-327.93-6.157-501.55-136.097-829.5-131.5-189.414 2.655-292.583 56.884-482 54.5z"
      ></path>
    </svg>
  );
}

const Clamp = styled.div`
  overflow: hidden;
`;
