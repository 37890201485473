import React from 'react';
import styled from 'styled-components';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import Spacer from '@components/Spacer';
import Image from '@components/Image';

const BonusFeatures = () => {
  return (
    <Wrapper>
      <PseudoTitle>With Nifty Bonuses</PseudoTitle>
      <IntroParagraph>
        The <em>Ultimate Tier</em> comes with exclusive extras:
      </IntroParagraph>
      <Row maxWidth={900}>
        <div>
          <TVCart alt="" src="/illustrations/tv-cart.png" />
        </div>
        <Description>
          <h3>The Video Archive</h3>
          <p>
            Have you ever stumbled on a particularly tricky layout or
            animation, and wondered how it works?
          </p>
          <p>
            The Video Archive is a collection of{' '}
            <strong>
              in-depth build walkthroughs and explanations.
            </strong>{' '}
            Often, when a student has a question around how to build
            an unconventional layout, I'll record a video for the
            archive showing how I would implement it.
          </p>
        </Description>
      </Row>
      <Spacer $size={64} />
      <Row flipped maxWidth={900}>
        <Description>
          <h3>The Resource Treasure Trove</h3>

          <p>Over the years, I've amassed a fortune of tools.</p>
          <p>
            The Resource Treasure Trove is a{' '}
            <strong>curated collection</strong> of my favourites. I
            share my favourite tools, generators, packages, and
            resources, with detailed guides for each one, showing
            exactly how I get the most out of it.
          </p>
        </Description>
        <div>
          <TreasureChest
            alt=""
            src="/illustrations/treasure-chest.png"
          />
        </div>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const DesktopBreak = styled.br`
  @media (max-width: 500px) {
    display: none;
  }
`;

const PseudoTitle = styled.em`
  display: block;
  margin-bottom: 16px;
  font-style: normal;
  font-size: min(48px, 9vw);
  font-weight: 500;
  text-align: center;
  background-image: linear-gradient(
    200.67deg,
    #ffffff 25%,
    hsl(54deg 100% 70%) 35%,
    hsl(36deg 100% 55%) 55.54%,
    hsl(333deg 100% 50%) 75%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  max-width: 600px;
  margin: 0 auto;
`;

const IntroParagraph = styled.p`
  font-size: 21px;
  text-align: center;
  margin-bottom: 64px;
`;

const Row = styled(MaxWidthWrapper)`
  display: flex;

  @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
    flex-direction: ${(p) =>
      p.flipped ? 'column-reverse' : 'column'};
    align-items: center;
  }
`;

const TVCart = styled(Image)`
  max-width: 250px;
  aspect-ratio: 768 / 1102;
`;

const TreasureChest = styled(Image)`
  max-width: 350px;
  aspect-ratio: 1089 / 861;

  @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
    width: 100%;
    max-width: 300px;
  }
`;

const Description = styled.div`
  /* The first one, with the TV stand on the left */
  &:first-child {
    padding-top: 32px;
    margin-right: 64px;
    text-align: right;

    @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
      /* HACK: Since the column is flipped in the second row */
      padding-top: 16px;
      text-align: center;
      margin-right: 0px;
    }
  }

  /* The second one, with the treasure chest on the right */
  &:not(:first-child) {
    padding-top: 64px;
    margin-left: 64px;

    @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
      text-align: center;
      margin-left: 0px;
      padding-top: 16px;
    }
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 16px;
  }
`;

export default BonusFeatures;
