import React from 'react';
import styled from 'styled-components';

import List from '@components/List';

const ModuleCard = React.forwardRef(
  (
    {
      number,
      title,
      x,
      y,
      children,
      description,
      bullets,
      ...delegated
    },
    ref
  ) => {
    return (
      <Wrapper ref={ref} {...delegated}>
        <Header>
          <Heading>
            <Prefix>Module {number}</Prefix>
            {title}
          </Heading>
        </Header>
        <MainArea>
          <p>{description}</p>
          <List>
            {bullets.map((bullet, bulletIndex) => (
              <List.ListItem key={bulletIndex}>
                {bullet}
              </List.ListItem>
            ))}
          </List>
        </MainArea>
      </Wrapper>
    );
  }
);

const Wrapper = styled.article`
  background: hsl(0deg 0% 100%);
  pointer-events: auto;
  border-radius: 6px 6px 4px 4px;

  li {
    font-size: 16px !important;
    margin-bottom: 0px !important;
  }
`;

const Header = styled.header`
  padding: 16px;
  background: linear-gradient(
    45deg,
    hsl(292deg 10% 15%),
    hsl(240deg 15% 20%)
  );
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  border-radius: 3px 3px 0 0;
`;

const Heading = styled.h3`
  font-size: 19px;
  font-weight: 500;
`;

const Prefix = styled.span`
  display: block;
  color: hsl(0deg 0% 100% / 0.75);
  font-size: 16px;
  font-weight: 300;
`;

const MainArea = styled.section`
  --selection-background-color: hsl(292deg 10% 15%);
  --selection-text-color: white;
  padding: 16px;
  padding-bottom: 20px;
  color: var(--color-background);
  font-size: 16px;

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export default ModuleCard;
