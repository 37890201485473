import React from 'react';
import styled from 'styled-components';

import { detectBrowser } from '@helpers/browser.helpers';

const TestimonialContent = ({
  authorName,
  authorTitle,
  authorAvatar,
  href,
  type,
  quoteStyle = {},
  children,
}) => {
  const fontSize = type === 'double' ? 21 : 24;

  const [browser, setBrowser] = React.useState(null);

  React.useEffect(() => {
    setBrowser(detectBrowser());
  }, []);

  return (
    <figure>
      <Avatar src={authorAvatar} alt="" />
      <Quote
        className={browser !== 'safari' && 'not-safari'}
        style={{
          '--font-size':
            typeof fontSize === 'number' ? `${fontSize}px` : fontSize,
          ...quoteStyle,
        }}
      >
        {children}
      </Quote>
      <Author>
        <a href={href}>{authorName}</a>
        {authorTitle}
      </Author>
    </figure>
  );
};

const Avatar = styled.img`
  display: block;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  margin: 0 auto 32px;
  user-select: none;
  /* prettier-ignore */
  box-shadow:
    0px 0px 0px 4px var(--color-background),
    0px 0px 0px 6px var(--color-gray-700)
  ;
`;

const Quote = styled.blockquote`
  position: relative;
  font-size: var(--font-size, 24px);
  font-weight: 300;
  text-align: center;
  margin: 0 16px;
  padding: 0 16px;

  @media (max-width: 700px) {
    font-size: 18px;
  }

  strong {
    color: hsl(45deg 100% 60%);
  }

  &.not-safari strong {
    background-color: #ffb23e;
    background-image: linear-gradient(
      182deg,
      #ffffff 15%,
      hsl(54deg 100% 70%),
      hsl(36deg 100% 55%)
    );
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  &:before,
  &:after {
    position: absolute;
    top: -25px;
    font-size: 72px;
    pointer-events: none;
    opacity: 0.25;
  }
  &:before {
    content: '“';
    left: 0;
    transform: translateX(-80%);
  }
  &:after {
    content: '”';
    right: 0;
    transform: translateX(80%);
  }
`;

const Author = styled.figcaption`
  margin-top: 32px;
  text-align: center;
  color: var(--color-gray-700);

  a {
    display: block;
    font-size: 21px;
    font-weight: 400;
    text-transform: uppercase;
    color: hsl(265deg 100% 80%);
    margin-bottom: 8px;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 5px;
      text-decoration-thickness: 1px;
    }
  }
`;

export default TestimonialContent;
