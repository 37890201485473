import React from 'react';
import styled from 'styled-components';

import MaxWidthWrapper from '@components/MaxWidthWrapper';

import { CARD_WIDTH } from './Curriculum.constants';
import FancyPath from './FancyPath';
import CardGrid from './CardGrid';
import AndMoreList from './AndMoreList';
import BonusFeatures from './BonusFeatures';
import VersionOnePointOne from './VersionOnePointOne';
import Spacer from '@components/Spacer';

const Curriculum = () => {
  return (
    <section>
      <Wrapper>
        <About>
          <h2>
            <span>The</span>
            Curriculum
          </h2>
          <p>
            This curriculum teaches everything you need to know to
            build modern, polished web experiences. It's a no-fluff
            distillation of the skills I've learned over 15 years.
          </p>
          <Facts>
            <Fact>
              <Num>200</Num>
              Lessons
            </Fact>
            <Fact>
              <SecondNum>40</SecondNum>
              Hours of
              <br />
              content
            </Fact>
          </Facts>
        </About>
      </Wrapper>
      <FancyPath />
      <CardGrid />
      <AndMoreList />
      <Svg viewBox="0 0 10 200" fill="none">
        <path
          d={`
            M 10 0
            C 60 100
              -30 100
              10 200
          `}
        />
      </Svg>
      <BonusFeatures />
      <Svg viewBox="0 0 10 200" fill="none">
        <path
          d={`
            M 10 0
            C -40 50
              30 150
              10 200
          `}
        />
      </Svg>
      <VersionOnePointOne />
    </section>
  );
};

const Wrapper = styled(MaxWidthWrapper)`
  padding-bottom: 96px;
  pointer-events: none;
  max-width: 1400px;

  @media (max-width: 1024px) {
    justify-content: center;
    padding-bottom: 48px;
  }
`;

const About = styled.div`
  max-width: 360px;
  text-align: justify;
  pointer-events: auto;

  /* Positioning it relative to the card */
  position: relative;
  left: calc(17% + ${CARD_WIDTH}px + 64px);

  @media (max-width: 1024px) {
    max-width: 450px;
    text-align: center;
    left: initial;
    margin: 0 auto;
  }

  @media (max-width: 500px) {
    text-align: left;
  }

  h2 {
    position: relative;
    font-size: min(73px, 17.5vw);
    /* font-size: 73px; */
    font-weight: 500;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    /* Optical alignment */
    transform: translateX(-2px);

    span {
      position: absolute;
      left: 17%;
      top: 30%;
      font-size: 24px;
      font-weight: 300;
      transform: translateY(-100%);
      color: var(--color-gray-700);
    }
  }

  p {
    font-size: 19px;
    margin-bottom: 42px;
  }
`;

const Facts = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Fact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: var(--color-gray-700);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
`;

const Num = styled.div`
  position: relative;
  font-size: 72px;
  font-weight: 900;
  letter-spacing: -4px;
  line-height: 80px;
  background-image: linear-gradient(
    200.67deg,
    #ffffff -5%,
    hsl(50deg 100% 65%) 50%,
    hsl(30deg 100% 50%) 70%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  &:after {
    content: '+';
    position: absolute;
    top: 0px;
    right: -2px;
    font-size: 32px;
    transform: translateX(100%);
    color: hsl(54deg 100% 90%);
    -webkit-text-fill-color: hsl(54deg 100% 90%) !important;
    -moz-text-fill-color: hsl(54deg 100% 90%) !important;
  }
`;

const SecondNum = styled(Num)`
  background-image: linear-gradient(
    200.67deg,
    #ffffff 5%,
    hsl(54deg 100% 70%) 50%,
    hsl(36deg 100% 55%) 70%
  );
`;

const Svg = styled.svg`
  display: block;
  margin: 48px auto 24px;
  width: 10px;
  overflow: visible;

  path {
    stroke: var(--color-gray-700);
    stroke-width: 5px;
    stroke-dasharray: 10px 16px;
    stroke-linecap: round;
    stroke-linejoin: round;
    vector-effect: non-scaling-stroke;
    backface-visibility: hidden;
  }
`;

export default Curriculum;
