import React from 'react';
import styled from 'styled-components';
import { Check } from 'react-feather';

import { API_ROOT, VISITOR_ID_KEY } from '@constants';
import useWindowDimensions from '@hooks/use-window-dimensions.hook';
import useSound from '@hooks/use-sound.hook';

import Spacer from '@components/Spacer';
import ClientOnly from '@components/ClientOnly';
import PoofyRainbowButton from '@components/RainbowButton/PoofyRainbowButton';
import BurstGeyser from '@components/ConfettiGeyser/BurstGeyser';
import InPortal from '@components/InPortal';
import Toast from '@components/Toast';

import ConfirmModal from './ConfirmModal';

const SignupForm = ({ id, focusOnMount, handleSuccess }) => {
  const [status, setStatus] = React.useState('idle');
  const [modal, setModal] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(null);

  const windowDimensions = useWindowDimensions();

  const geyserPosition = React.useMemo(() => {
    return [
      windowDimensions.width,
      Math.min(700, windowDimensions.height),
    ];
  }, [windowDimensions.width, windowDimensions.height]);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setError(null);

    setStatus('submitting');

    const visitorId = window.localStorage.getItem(VISITOR_ID_KEY);

    fetch(API_ROOT + '/newsletter/email-preview', {
      method: 'POST',
      body: JSON.stringify({
        email,
        visitorId,
        courseSlug: 'css-for-js',
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.error) {
          throw json;
        }

        setStatus('completed');
        setModal('success');

        if (typeof handleSuccess === 'function') {
          handleSuccess(email);
        }
      })
      .catch((err) => {
        setStatus('idle');
        // TODO: multiple errors?
        setError(true);
        console.error(err);
      });
  };

  return (
    <>
      {modal === 'success' && (
        <ConfirmModal
          email={email}
          handleDismiss={() => setModal(null)}
        />
      )}

      <Wrapper onSubmit={handleSubmit}>
        <InputWrapper status={status}>
          <EmailInput
            id={id}
            type="email"
            placeholder="you@domain.com"
            value={email}
            onChange={(ev) => setEmail(ev.currentTarget.value)}
            disabled={status !== 'idle'}
            autoComplete="email"
            required
          />
          <Spacer $size={8} />
          {status === 'completed' && (
            <CheckmarkHolder>
              <Check color="var(--color-success)" />
            </CheckmarkHolder>
          )}

          {status !== 'completed' && (
            <ClientOnly>
              <SubmitButton
                disabled={status !== 'idle'}
                intervalDelay={500}
              >
                Get the Preview
              </SubmitButton>
            </ClientOnly>
          )}
        </InputWrapper>

        <Disclaimer>
          No spam, no nonsense. Unsubscribe at any time.{' '}
          <PolicyLink
            href="/email-course-preview"
            target="_blank"
            rel="noopener nofererrer"
          >
            Read more
          </PolicyLink>
          .
        </Disclaimer>
      </Wrapper>

      {error && (
        <Toast
          type="error"
          title="Something went wrong"
          handleDismiss={() => setError(null)}
        >
          Unfortunately, your course preview could not be created.
          Please try again. If it still doesn't work, please{' '}
          <a href="mailto:support@joshwcomeau.com">contact support</a>
          .
        </Toast>
      )}
    </>
  );
};

const Wrapper = styled.form`
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  color-scheme: light;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fff;
  border-radius: 4px;
  padding: 8px;
  box-shadow: -06px 0.6px 1px -7px rgba(0, 0, 0, 0.02),
    -1.4px 1.4px 2.5px -7px rgba(0, 0, 0, 0.028),
    -2.6px 2.6px 4.6px -7px rgba(0, 0, 0, 0.035),
    -5px 4.7px 8.3px -7px rgba(0, 0, 0, 0.042),
    -8px 8.8px 15.5px -7px rgba(0, 0, 0, 0.05),
    -15px 21px 37px -7px rgba(0, 0, 0, 0.07);

  /*
    On smaller screens, the input and button are stacked vertically.
    On larger screens, they can sit side-by-side.
    When the
  */
  @media (min-width: 28.125rem) {
    flex-direction: row;
  }
`;

const EmailInput = styled.input`
  display: block;
  height: 48px;
  padding-left: 8px;
  border: none;
  background: transparent;
  font-size: 16px;
  color: black;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media ${(p) => p.theme.breakpoints.smAndLarger} {
    font-size: 18px;
  }

  @media (min-width: 28.125rem) {
    flex: 1;
  }

  @media ${(p) => p.theme.breakpoints.mdAndLarger} {
    min-width: 325px;
  }
`;

const SubmitButton = styled(PoofyRainbowButton)`
  /* On small screens, fill the available space */
  width: 100%;
  height: 48px;
  font-size: 16px;
  padding: 0 32px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (min-width: 28.125rem) {
    /*
      On larger screens, take up the space needed for the button
      contents
    */
    width: max-content;
  }

  @media ${(p) => p.theme.breakpoints.smAndLarger} {
    height: 48px;
    font-size: 18px;
  }
`;

const CheckmarkHolder = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 20px;
  width: 24px;
  height: 24px;
  margin: auto;
  /* Show the "disabled" pointer for the input behind this icon */
  pointer-events: none;

  svg {
    display: block;
  }
`;

const Disclaimer = styled.p`
  font-size: 1em;
  margin: auto;
  margin-top: 24px;
  text-align: center;
  padding: 0 8px;
  opacity: 0.75;
`;

const PolicyLink = styled.a`
  color: inherit;
  text-decoration: underline;
  text-decoration-thickness: 1px;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
`;

export default SignupForm;
