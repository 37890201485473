import React from 'react';
import styled, { keyframes } from 'styled-components';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { X as XIcon } from 'react-feather';

import UnstyledButton from '@components/UnstyledButton';

function Modal({
  label,
  theme = 'light',
  maxWidth = 570,
  children,
  handleDismiss,
}) {
  const ModalContent =
    theme === 'light' ? LightModalContent : DarkModalContent;
  const CloseButton =
    theme === 'light' ? LightCloseButton : DarkCloseButton;

  const maxWidthWithUnit =
    typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth;

  return (
    <ModalWrapper onDismiss={handleDismiss}>
      <Overlay />
      <ModalInnerWrapper>
        <ModalContent
          aria-label={label}
          style={{ '--max-width': maxWidthWithUnit }}
        >
          <CloseButton onClick={handleDismiss}>
            <XIcon size={32} />
          </CloseButton>
          {children}
        </ModalContent>
      </ModalInnerWrapper>
    </ModalWrapper>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const riseIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px) ;
  }
  to {
    opacity: 1;
    transform: translateY(0px) ;
  }
`;

const ModalWrapper = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: hsl(0deg, 0%, 10%);
`;

const ModalInnerWrapper = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  animation: ${riseIn} 550ms 500ms both;
  will-change: transform;
  /* Allow the page to scroll if it doesn't fit */
  max-height: 100vh;
  overflow: auto;
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(0deg, 0%, 0%, 0.95);
  animation: ${fadeIn} 400ms both;
  will-change: transform;

  @supports (backdrop-filter: blur(10px)) {
    background-color: hsla(0deg, 0%, 0%, 0.7);
    backdrop-filter: blur(10px);
  }
`;

const ModalContent = styled(DialogContent)`
  position: relative;
  width: min(100%, var(--max-width));
  outline: none;

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    width: 100%;
  }
`;

const LightModalContent = styled(ModalContent)`
  align-self: center;
  padding: 32px;
  padding-bottom: 0;
  background: white;
  border-radius: 6px;
  /* prettier-ignore */
  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    -1px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    -2px 12.5px 10px rgba(0, 0, 0, 0.035),
    -5px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    -10px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    -20px 100px 80px rgba(0, 0, 0, 0.07);

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    border-radius: 0px;
    height: 100%;
  }
`;
const DarkModalContent = styled(ModalContent)`
  position: absolute;
  padding: 72px 16px 0;
  color: white;

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    padding-top: 128px;
    padding-bottom: 128px;
    min-height: 100%;
  }
`;

const LightCloseButton = styled(UnstyledButton)`
  padding: 16px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(calc(-100% - 8px));
  color: white;

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    transform: translateY(0px);
    color: black;
  }
`;

const DarkCloseButton = styled(UnstyledButton)`
  padding: 16px;
  position: fixed;
  top: 32px;
  right: 32px;
  color: white;

  svg {
    display: block;
  }

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    top: 0px;
    right: 0px;
  }
`;

export default Modal;
