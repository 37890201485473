import React from 'react';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import TestimonialContent from '@components/TestimonialContent';
import TestimonialWrapper from '@components/TestimonialWrapper';

const Testimonial = ({ maxWidth, ...delegated }) => {
  return (
    <TestimonialWrapper>
      <MaxWidthWrapper maxWidth={maxWidth}>
        <TestimonialContent {...delegated} />
      </MaxWidthWrapper>
    </TestimonialWrapper>
  );
};

export default Testimonial;
