import React from 'react';
import styled from 'styled-components';

import MaxWidthWrapper from '@components/MaxWidthWrapper';

import data from './Faq.data';
import Question from './Question';

const Faq = () => {
  return (
    <Wrapper as="section" maxWidth={1600}>
      <Header>
        <Svg viewBox="0 0 100 200" fill="none">
          <path
            d={`
              M 50 0
              C 120 66
                -20 180
                50 200
            `}
            stroke="white"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Svg>
        <h2>Frequently Asked Questions</h2>
      </Header>

      <Grid>
        {data.map((item) => (
          <Question
            key={item.q}
            question={item.q}
            answer={item.a}
            slug={item.slug}
          />
        ))}
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled(MaxWidthWrapper)`
  h2 {
    font-size: 48px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 72px;
    text-align: center;

    @media (max-width: 500px) {
      padding: 0 16px;
    }
  }

  /*
    HACK: Reaching into children.
    This is a bad practice indeed, I'm just being lazy.
  */
  a {
    color: hsl(265deg 100% 80%);
  }

  ul {
    list-style-type: initial;
    padding-left: 20px;
  }

  li {
    margin-bottom: 12px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 32px;
  padding: 0 32px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 0 16px;
  }
`;

const Svg = styled.svg`
  display: block;
  overflow: visible;
  width: 100px;

  path {
    stroke: var(--color-gray-700);
    stroke-width: 2px;
    stroke-dasharray: 10px 16px;
    stroke-linecap: round;
    stroke-linejoin: round;
    vector-effect: non-scaling-stroke;
    backface-visibility: hidden;
  }
`;

export default Faq;
