import React from 'react';
import styled from 'styled-components';
import { Slash } from 'react-feather';

import { COURSE_PLATFORM_ROOT } from '@constants';

import Spacer from '@components/Spacer';
import Image from '@components/Image';

import Checklist from './Checklist';
import PriceDisplay from './PriceDisplay';
import IncludedModules from './IncludedModules';

function SideCard({ tier, pricingInfo, isAvailable }) {
  const trophySrc =
    tier === 'basic'
      ? '/illustrations/bronze.png'
      : '/illustrations/silver-glow.png';
  const trophyWidth = tier === 'basic' ? 50 : 145;
  const trophyTranslate = tier === 'basic' ? -90 : -93;

  // TODO: Fetch from server?
  const numOfIncludedModules = tier === 'basic' ? 4 : 7;

  let href = `${COURSE_PLATFORM_ROOT}/checkout/css-for-js/${tier}`;

  return (
    <Wrapper
      tier={tier}
      style={{
        gridArea: tier,
        '--filter': !isAvailable
          ? 'grayscale(100%)'
          : 'grayscale(0%)',
      }}
    >
      <Title>
        <Prefix>The</Prefix>
        {tier === 'basic' ? 'Basic' : 'Pro'} Package
      </Title>
      <Spacer $size={16} />

      <Summary>
        {tier === 'basic'
          ? 'Get started learning the fundamentals of the CSS language.'
          : 'Build responsive, optimized websites and web applications.'}
      </Summary>

      <Body>
        <PriceDisplay pricingInfo={pricingInfo} />
        <Spacer $size={16} />

        {isAvailable ? (
          <>
            <BuyLink href={href}>Register Now</BuyLink>
            <Spacer $size={16} />
            <Footnote>
              Price shown in USD, excluding sales tax.
            </Footnote>
            <Spacer $size={32} />
          </>
        ) : (
          <RegionalDisclaimer>
            <SlashIcon size={24} />
            <p>Not available with regional license</p>
          </RegionalDisclaimer>
        )}

        <Checklist
          items={[
            <span>
              Access to {numOfIncludedModules} out of 10 modules
            </span>,
            <span>
              <strong>Lifetime access</strong> to all course materials
            </span>,
            <span>Exclusive Discord community</span>,
            <span>30-day money-back guarantee</span>,
          ]}
        />

        <Spacer $size={32} />

        <IncludedModules num={numOfIncludedModules} />
      </Body>
      {!isAvailable && (
        <Gradient
          style={{
            '--closer': 'hsl(40deg 60% 5% / 0.3)',
            '--farther': 'hsl(40deg 60% 5% / 0.7)',
            '--angle': tier === 'basic' ? '90deg' : '-90deg',
            '--gradient': `linear-gradient(
              var(--angle),
              var(--farther),
              var(--closer)
            )`,
          }}
        />
      )}
      <Trophy
        alt=""
        src={trophySrc}
        style={{
          width: trophyWidth,
          transform: `translateY(${trophyTranslate}%)`,
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.article`
  position: relative;
  padding: 4px;
  max-width: 500px;
  border-radius: 8px 8px 8px 8px;
  background: white;
  display: flex;
  flex-direction: column;
  color: hsl(223deg, 10%, 20%);
  box-shadow: 0px 0px 20px hsl(0deg 0% 0% / 0.5);
  filter: var(--filter);
  --selection-background-color: hsl(0deg 0% 10% / 0.75);
  --selection-text-color: hsl(0deg, 0%, 100%);
`;

const Trophy = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-20%);
`;

const Body = styled.div`
  padding: 12px;
`;

const Summary = styled.p`
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 0;
  padding: 0 12px;
`;

const Title = styled.h3`
  position: relative;
  font-size: 24px;
  font-weight: 500;
  align-self: stretch;
  text-align: center;
  padding: 24px 0;
  border-radius: 4px 4px 0 0;
  color: black;
  background: hsl(0deg 0% 90%);
`;

const Prefix = styled.span`
  display: block;
  font-size: 0.666em;
  font-weight: 300;
  color: hsl(223deg, 10%, 40%);
  text-align: center;
  margin-bottom: -8px;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: hsl(0deg 0% 0% / 0.25);

  @media (min-width: 946px) {
    background: var(--gradient);
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin: 0 -16px -16px;
  background: hsl(0deg 0% 90%);
  border-radius: 0px 0px 10px 10px;
  color: hsl(0deg 0% 40%);
  /* HACK: This number copied in UltimateCard */
  height: 91px;

  p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin: 0px;
    margin-top: 8px;
  }
`;

const RegionalDisclaimer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background: hsl(0deg 0% 0% / 0.1);
  padding: 16px 8px;
  margin: 24px 0;
  border-radius: 4px;

  p {
    margin-bottom: 0;
  }
`;

const SlashIcon = styled(Slash)`
  opacity: 0.75;
`;

const BuyLink = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
  text-decoration: none;
  transition: filter 600ms;
  background: hsl(223deg, 10%, 20%);
  color: white;
  font-size: 19px;
  font-weight: 400;
  border-radius: 6px;

  &:hover {
    transition: filter 250ms;
    filter: brightness(110%);
    text-decoration: none;
  }
`;

const Footnote = styled.p`
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 0;
`;

export default SideCard;
