import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import BuyButton from '@components/BuyButton';
import Image from '@components/Image';

const IS_BLACK_FRIDAY = false;

const TeamLicenseBlurb = () => {
  return (
    <Wrapper maxWidth={1000}>
      <ImageCol>
        <picture>
          <BlobImg alt="" src="/illustrations/team-blobs.png" />
        </picture>
      </ImageCol>
      <MainCol>
        <h2>Level up the whole team ✨</h2>
        {IS_BLACK_FRIDAY ? (
          <p>
            Train multiple devs at your organization with{' '}
            <strong>Team Licenses</strong>. You'll get the same{' '}
            <strong>50% Black Friday discount</strong>, plus you'll be
            able to manage your team through our Team Administrator
            dashboard.
          </p>
        ) : (
          <p>
            Train multiple devs at your organization with{' '}
            <strong>Team Licenses</strong>. Receive a discount when
            you buy at least 4 copies, and manage them through our
            custom Team Administrator dashboard.
          </p>
        )}

        <BuyLink to="/teams">Learn More</BuyLink>
      </MainCol>
    </Wrapper>
  );
};

const Wrapper = styled(MaxWidthWrapper)`
  text-align: center;

  h2 {
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    margin-bottom: 24px;
  }
  p {
    font-size: 1.125rem;
    max-width: 45ch;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 400px) {
    h2 {
      font-size: 2rem;
    }
  }
  @media (min-width: 685px) {
    h2 {
      font-size: 3rem;
    }
  }

  @media ${(p) => p.theme.breakpoints.lgAndLarger} {
    display: flex;
    gap: 32px;
    text-align: left;

    p {
      margin-left: revert;
      margin-right: revert;
    }
  }
`;

const ImageCol = styled.div`
  margin-bottom: 32px;

  @media ${(p) => p.theme.breakpoints.lgAndLarger} {
    width: 250px;
    margin-bottom: unset;
  }
`;

const MainCol = styled.div`
  @media ${(p) => p.theme.breakpoints.lgAndLarger} {
    flex: 1;
  }
`;

const BlobImg = styled(Image)`
  display: block;
  max-width: 300px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media ${(p) => p.theme.breakpoints.lgAndLarger} {
    width: 150%;
    margin-left: -25%;
    margin-right: unset;
    transform: translateY(32px);
  }
`;

const BuyLink = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
  margin: 0 auto;
  text-decoration: none;
  transition: filter 600ms;
  background: hsl(223deg, 10%, 20%);
  color: white;
  font-size: 19px;
  font-weight: 400;
  border-radius: 6px;
  max-width: 250px;

  &:hover {
    transition: filter 250ms;
    filter: brightness(130%);
    text-decoration: none;
  }
  @media ${(p) => p.theme.breakpoints.lgAndLarger} {
    margin: initial;
  }
`;

export default TeamLicenseBlurb;
