import React from 'react';
import styled from 'styled-components';
import { ChevronUp, ChevronDown } from 'react-feather';

import useToggle from '@hooks/use-toggle.hook';

import FakeTweet from '@components/FakeTweet';
import MaxWidthWrapper from '@components/MaxWidthWrapper';

import tweetData from '../../tweet-testimonials.json';
import UnstyledButton from '@components/UnstyledButton';

const tweetArray = Object.entries(tweetData);

const WallOfLove = () => {
  const [isExpanded, toggleExpanded] = useToggle(false);

  return (
    <Wrapper>
      <MaxWidthWrapper>
        <Title id="wall-of-love">What people are saying…</Title>
      </MaxWidthWrapper>
      <GridWrapper
        style={{
          '--height': isExpanded ? undefined : '110vh',
          paddingBottom: isExpanded ? 128 : 0,
        }}
      >
        <TweetGrid>
          {tweetArray.map(([id, data]) => (
            <GridItem
              key={id}
              id={id}
              displayName={data.name}
              handle={data.handle}
              date={data.date}
              verified={data.verified}
              avatarSrc={`/avatars/${data.handle}.${
                data.avatarFormat || 'jpg'
              }`}
              children={data.message}
              // There are A LOT of tweets.
              // Let's only make them focusable if the user has opted
              // to expand the section.
              enableFocus={isExpanded}
            />
          ))}
        </TweetGrid>
        <BottomGradient style={{ opacity: isExpanded ? 0 : 1 }} />

        <Expand
          onClick={toggleExpanded}
          style={{ bottom: isExpanded ? 0 : 96 }}
        >
          <ExpandBackdrop />
          <ExpandContents>
            {isExpanded ? (
              <>
                <ChevronUp />
                Show Less
              </>
            ) : (
              <>
                <ChevronDown />
                Show More
              </>
            )}
          </ExpandContents>
        </Expand>
      </GridWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section``;

const Title = styled.h2`
  font-size: 48px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 72px;
  text-align: center;
  scroll-margin-top: 96px;

  @media (max-width: 500px) {
    padding: 0 16px;
  }
`;

const GridWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 48px;
  overflow: hidden;
  max-width: 1570px;
  height: var(--height);

  @media (max-width: 500px) {
    padding: 0 16px;
    /*
      On mobile, we can only fit like 3 tweets in the viewport
      at one time. Let's crank that up.
    */
    height: calc(var(--height) * 2);
  }
`;

const Gradient = styled.div`
  position: absolute;
  /* Scooch clear of the scrollbar */
  right: 16px;
  left: 16px;
  height: 256px;
  pointer-events: none; ;
`;

const BottomGradient = styled(Gradient)`
  bottom: 0;
  background: linear-gradient(
    to bottom,
    hsl(274deg 16% 8% / 0) 0%,
    hsl(274deg 16% 8% / 0.013) 8.1%,
    hsl(274deg 16% 8% / 0.049) 15.5%,
    hsl(274deg 16% 8% / 0.104) 22.5%,
    hsl(274deg 16% 8% / 0.175) 29%,
    hsl(274deg 16% 8% / 0.259) 35.3%,
    hsl(274deg 16% 8% / 0.352) 41.2%,
    hsl(274deg 16% 8% / 0.45) 47.1%,
    hsl(274deg 16% 8% / 0.55) 52.9%,
    hsl(274deg 16% 8% / 0.648) 58.8%,
    hsl(274deg 16% 8% / 0.741) 64.7%,
    hsl(274deg 16% 8% / 0.825) 71%,
    hsl(274deg 16% 8% / 0.896) 77.5%,
    hsl(274deg 16% 8% / 0.951) 84.5%,
    hsl(274deg 16% 8% / 0.987) 91.9%,
    hsl(274deg 16% 8%) 100%
  );
`;

const TweetGrid = styled.div`
  /* display: grid;
  grid-template-columns: repeat(5, 1fr); */
  column-count: 4;
  column-gap: 16px;

  @media (max-width: 1450px) {
    column-count: 3;
  }
  @media (max-width: 1023px) {
    column-count: 2;
  }
  @media (max-width: 767px) {
    column-count: 1;
  }
`;

const GridItem = styled(FakeTweet)`
  break-inside: avoid;
  margin-bottom: 16px;
`;

const Expand = styled(UnstyledButton)`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 1.25rem;
  padding: 16px;
  padding-right: 24px;
  color: white;
  width: 200px;
`;

const ExpandContents = styled.span`
  /* Sit above the backdrop */
  position: relative;
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;
  }
`;

const ExpandBackdrop = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsl(274deg 16% 8%);
  opacity: 0.9;
  border-radius: 8px;
  transition: transform 350ms, opacity 600ms 100ms;
  will-change: transform;

  ${Expand}:hover & {
    transition: transform 100ms, opacity 120ms;
    transform: scale(1.04, 1.1);
    opacity: 1;
  }
`;

export default WallOfLove;
