import React from "react";
import styled from "styled-components";

function Spotlight({ width, side = "right", ...delegated }) {
  const idRoot = `spotlight-${side}`;

  return (
    <Wrapper {...delegated}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        fill="none"
        viewBox="0 0 2441 2224"
        style={{ transform: side === "left" ? "rotateY(180deg)" : undefined }}
      >
        <g filter={`url(#${idRoot}0_f)`}>
          <path
            fill={`url(#${idRoot}0_linear)`}
            fillOpacity="0.5"
            d="M1133.48 511.488l792.11 1178.782 16.91 11.14-11 9.59-5.91-9.59L506.336 935.49l174.152-325.932 452.992-98.07z"
          ></path>
        </g>
        <g filter={`url(#${idRoot}1_f)`}>
          <path
            fill={`url(#${idRoot}1_linear)`}
            d="M1133.48 511.488l792.11 1178.782 14.91 13.73h-7L506.336 935.49l174.151-325.932 452.993-98.07z"
          ></path>
        </g>
        <g filter={`url(#${idRoot}2_f)`}>
          <path
            fill={`url(#${idRoot}2_linear)`}
            fillOpacity="0.2"
            d="M1621.5 538l304.09 1152.27 28.91 54.23-53.5 11L629.5 1342l50.99-732.441L1621.5 538z"
          ></path>
        </g>
        <g filter={`url(#${idRoot}3_f)`}>
          <path
            fill={`url(#${idRoot}3_linear)`}
            d="M1133.48 511.488l792.11 1178.782L506.336 935.49l174.152-325.932 452.992-98.07z"
          ></path>
        </g>
        <defs>
          <filter
            id={`${idRoot}0_f`}
            width="1641.84"
            height="1429"
            x="400.786"
            y="400.897"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            ></feBlend>
            <feGaussianBlur
              result="effect1_foregroundBlur"
              stdDeviation="50"
            ></feGaussianBlur>
          </filter>
          <filter
            id={`${idRoot}1_f`}
            width="2439.71"
            height="2222.09"
            x="0.786"
            y="0.924"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            ></feBlend>
            <feGaussianBlur
              result="effect1_foregroundBlur"
              stdDeviation="250"
            ></feGaussianBlur>
          </filter>
          <filter
            id={`${idRoot}2_f`}
            width="1735.7"
            height="1638.64"
            x="418.941"
            y="333.769"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            ></feBlend>
            <feGaussianBlur
              result="effect1_foregroundBlur"
              stdDeviation="100"
            ></feGaussianBlur>
          </filter>
          <filter
            id={`${idRoot}3_f`}
            width="1574.8"
            height="1357.97"
            x="425.787"
            y="426.121"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            ></feBlend>
            <feGaussianBlur
              result="effect1_foregroundBlur"
              stdDeviation="37.5"
            ></feGaussianBlur>
          </filter>
          <linearGradient
            id={`${idRoot}0_linear`}
            x1="921.665"
            x2="1972.19"
            y1="753.838"
            y2="1745.42"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" stopOpacity="0"></stop>
            <stop offset="1" stopColor="#fff"></stop>
          </linearGradient>
          <linearGradient
            id={`${idRoot}1_linear`}
            x1="921.665"
            x2="1972.19"
            y1="753.838"
            y2="1745.42"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" stopOpacity="0"></stop>
            <stop offset="1" stopColor="#fff"></stop>
          </linearGradient>
          <linearGradient
            id={`${idRoot}2_linear`}
            x1="921.666"
            x2="1972.19"
            y1="753.837"
            y2="1745.42"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" stopOpacity="0"></stop>
            <stop offset="1" stopColor="#fff"></stop>
          </linearGradient>
          <linearGradient
            id={`${idRoot}3_linear`}
            x1="1422"
            x2="1971.74"
            y1="1225"
            y2="1745.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" stopOpacity="0"></stop>
            <stop offset="0.318" stopColor="#fff" stopOpacity="0.18"></stop>
            <stop offset="0.609" stopColor="#fff" stopOpacity="0.36"></stop>
            <stop offset="1" stopColor="#fff"></stop>
          </linearGradient>
        </defs>
      </Svg>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  pointer-events: none;
`;

const Svg = styled.svg`
  display: block;
`;

export default Spotlight;
