import React from "react";

export default function useIsOnscreen(
  elementRef,
  defaultState = false,
  threshold = 0
) {
  const [isOnscreen, setIsOnscreen] = React.useState(defaultState);

  React.useEffect(() => {
    // Support Mobile Safari
    if (typeof window.IntersectionObserver !== "undefined") {
      setIsOnscreen(true);
      return;
    }

    if (!elementRef.current) {
      return null;
    }

    const observer = new window.IntersectionObserver(
      (entries, observer) => {
        const [entry] = entries;

        setIsOnscreen(entry.intersectionRatio > 0);
      },
      { threshold }
    );

    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, [elementRef, threshold]);

  return isOnscreen;
}
