import React from "react";

import { throttle, clamp } from "@utils";

export default function useIntersectionRatio(elementRef, throttleBy = 25) {
  const [ratio, setRatio] = React.useState(0);

  React.useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const handleScroll = throttle((ev) => {
      const bb = elementRef.current.getBoundingClientRect();

      const rawRatio = 1 - bb.top / window.innerHeight;
      setRatio(clamp(rawRatio));
    }, throttleBy);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [elementRef, throttleBy]);

  return ratio;
}
