import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import useSound from '@hooks/use-sound.hook';

const BORDER_WIDTH = 2;

const Checkbox = ({
  size = 18,
  name,
  checked,
  children,
  onChange,
  disabled,
  ...delegated
}) => {
  const [active, setActive] = React.useState(false);

  const springConfig = {
    tension: 400,
    friction: 22,
    clamp: !checked,
  };

  const filledScale = checked ? (active ? 1 / 0.9 : 1) : 0;
  const filledSpring = useSpring({
    transform: `scale(${filledScale})`,
    config: springConfig,
  });

  const outlineScale = active ? 0.9 : 1;
  const outlineSpring = useSpring({
    transform: `scale(${outlineScale})`,
    config: springConfig,
  });

  return (
    <Wrapper>
      <RealCheckbox
        onMouseDown={() => {
          setActive(true);
        }}
        onMouseUp={() => {
          setActive(false);
        }}
        onChange={onChange}
        disabled={disabled}
        {...delegated}
      />
      <VisibleContents style={{ opacity: disabled ? 0.5 : 1 }}>
        <VisibleBox
          style={{ width: size, height: size, ...outlineSpring }}
        >
          <Filled style={filledSpring} />
        </VisibleBox>

        {children}
      </VisibleContents>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const RealCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const VisibleContents = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
`;

const VisibleBox = styled(animated.div)`
  position: relative;
  border: ${BORDER_WIDTH}px solid hsl(223deg, 5%, 80%);
  border-radius: 4px;
  margin-right: 8px;
  will-change: transform;

  ${RealCheckbox}:hover + ${VisibleContents} & {
    border-color: var(--color-gray-1000);
  }

  ${RealCheckbox}:focus.focus-visible + ${VisibleContents} & {
    outline: 2px auto var(--color-primary);
    outline-offset: 2px;
  }
`;

const Filled = styled(animated.div)`
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: hsl(190deg 100% 50%);
  border-radius: 2px;
  will-change: transform;
`;

export default Checkbox;
