import React from 'react';
import styled from 'styled-components';

import Sparkles from '@components/Sparkles';
import SignupForm from '@components/SignupForm';

function EmailPreview({ handleSuccess }) {
  return (
    <Wrapper>
      <Sparkles
        minSize={20}
        maxSize={30}
        rate={2000}
        variance={1500}
        colors={['#FFF']}
      >
        <Flag>NEW</Flag>
      </Sparkles>
      <Preamble>
        Enter your email to receive{' '}
        <strong>a free preview of the course</strong>:
      </Preamble>
      <SignupForm handleSuccess={handleSuccess} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
`;

const Flag = styled.span`
  font-weight: var(--font-weight-bold);
  font-size: 1rem;
  color: var(--color-tertiary);
`;

const Preamble = styled.p`
  font-size: 1.25rem;
`;

export default EmailPreview;
