import React from 'react';
import styled, { keyframes } from 'styled-components';

import { MODE } from '@constants';

import Spacer from '@components/Spacer';
import { HeroSwoop } from '@components/Swoops';
import ClientOnly from '@components/ClientOnly';

import EmailPreview from './EmailPreview';

const Hero = ({ handleSignup }) => {
  const [isHoveringAuthor, setIsHoveringAuthor] = React.useState(
    false
  );

  return (
    <Wrapper>
      <Gradient />
      <Swoop />
      <Stuff>
        <Heading>
          Stop wrestling <br />
          with CSS.
        </Heading>
        <Spacer $size={24} />
        <Subheading>
          <DesktopText>
            The interactive interactive learning experience designed
            to help JavaScript developers become confident with CSS.
          </DesktopText>
          <MobileText>
            The interactive learning experience designed to help JS
            devs become confident with CSS.
          </MobileText>
        </Subheading>
        <Spacer $size={32} />
        <Author>
          <img
            src={
              isHoveringAuthor ? '/me-hover.jpg' : '/me-avatar.jpg'
            }
            // eslint-disable-next-line
            alt="A very handsome fella with swoopy hair"
          />
          <Spacer $size={16} />
          <div>
            Taught by&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/JoshWComeau"
              onMouseEnter={() => setIsHoveringAuthor(true)}
              onMouseLeave={() => setIsHoveringAuthor(false)}
            >
              Josh W Comeau
            </a>
          </div>
        </Author>
        <SpecialSpacer />
        <EmailPreviewWrapper>
          <EmailPreview handleSuccess={handleSignup} />
        </EmailPreviewWrapper>
      </Stuff>
    </Wrapper>
  );
};

const MOBILE_THRESHOLD = 768;

const Wrapper = styled.section`
  position: relative;
  min-height: max(750px, 100vh);
`;

const Gradient = styled.div`
  position: absolute;
  z-index: -3;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 100%, 0) 0%,
    hsla(0, 0%, 100%, 0.14) 100%
  );
`;

const Swoop = styled(HeroSwoop)`
  position: absolute;
  z-index: -2;
  left: 0;
  right: 0;
  bottom: -75px;
`;

const Stuff = styled.div`
  height: 100%;
  min-height: 650px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 128px 16px;

  @media (max-width: ${MOBILE_THRESHOLD}px) {
    align-items: flex-start;
    padding: 128px 24px 0;
  }
`;

const Heading = styled.h1`
  font-size: 72px;
  font-size: min(72px, 8vw);
  font-weight: 500;
  margin: 0;
  width: max-content;
  text-align: center;
  background-color: #ffb23e;
  background-image: linear-gradient(
    268.67deg,
    #ffffff 3.43%,
    hsl(54deg 100% 70%) 15.69%,
    hsl(36deg 100% 55%) 55.54%,
    hsl(333deg 100% 50%) 99%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  & > br {
    display: none;
  }

  @media (max-width: ${MOBILE_THRESHOLD}px) {
    font-size: 12vw;
    text-align: left;
    line-height: 1.2;

    & > br {
      display: block;
    }
  }
`;

const Subheading = styled.h2`
  font-size: 30px;
  font-weight: 300;
  max-width: 840px;
  text-align: center;

  @media (max-width: ${MOBILE_THRESHOLD}px) {
    text-align: left;
    font-size: 21px;
  }
`;

const DesktopText = styled.span`
  display: block;

  @media (max-width: 900px) {
    font-size: 27px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;
const MobileText = styled.span`
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-gray-700);
  font-size: 18px;
  cursor: default;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  a {
    font-weight: 400;
    color: white;
    text-decoration: none;
  }

  @media (max-width: ${MOBILE_THRESHOLD}px) {
    align-self: center;
  }
`;

const SpecialSpacer = styled.div`
  --height: max(12vh, 64px);
  height: var(--height);
  max-height: var(--height);
`;

const enterAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-8px)
  }
  to {
    opacity: 1;
    transform: translateY(0px)
  }
`;

const EmailPreviewWrapper = styled.div`
  animation: ${enterAnimation} 1000ms
    cubic-bezier(0.17, 0.67, 0.32, 1);
`;

export default Hero;
