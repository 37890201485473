import React from 'react';
import styled from 'styled-components';
import { Heart, User } from 'react-feather';

import Spacer from '@components/Spacer';
import DesktopOnly from '@components/DesktopOnly';
import TwitterLogo from './TwitterLogo';
import Metric from './Metric';
import VerifiedFlag from './VerifiedFlag';

const FakeTweet = ({
  id,
  avatarSrc,
  displayName,
  handle,
  date,
  verified,
  enableFocus,
  children,
  ...delegated
}) => {
  const twitterUrl = `https://twitter.com/${handle}/status/${id}`;

  return (
    <Link
      target="_blank"
      rel="noopener noreferer"
      href={twitterUrl}
      aria-label="Open Tweet on Twitter"
      tabIndex={enableFocus ? undefined : -1}
      {...delegated}
    >
      <Wrapper>
        <Header>
          <Avatar src={avatarSrc} alt="" />
          <Spacer $size={12} />
          <UserInfo>
            <DisplayName>
              {displayName} {verified && <VerifiedFlag />}
            </DisplayName>
            <Handle>@{handle}</Handle>
          </UserInfo>
          <TwitterLogo
            style={{ width: 24, alignSelf: 'flex-start' }}
          />
        </Header>
        <Body>{children}</Body>
      </Wrapper>
    </Link>
  );
};

const Link = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  padding: 16px;
  background: white;
  color: var(--color-background);
  border-radius: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;

  --color-gray-600: hsl(223deg, 5%, 40%);
  --color-gray-1000: hsl(223deg, 10%, 10%);

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 2px auto var(--color-primary);
    outline-offset: 2px;
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
`;
const Wrapper = styled.article``;

const Header = styled.header`
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  display: block;
  width: 38px;
  height: 38px;
  border-radius: 24px;
`;

const UserInfo = styled.div`
  flex: 1;
`;

const DisplayName = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 15px;
  line-height: 1.3;
  color: var(--color-gray-1000);
  font-weight: 800;
`;

const Handle = styled.div`
  font-size: 15px;
  line-height: 1.3;
  font-weight: 500;
  color: var(--color-gray-600);
`;

const Body = styled.p`
  font-size: 18px;
  padding: 16px 0 0;
  font-weight: 500;
  white-space: pre-wrap;
  line-height: 1.25;
  color: var(--color-gray-1000);
  margin-bottom: 0;
`;

const Date = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: var(--color-gray-600);
`;

export default FakeTweet;
