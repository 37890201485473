export const CARD_WIDTH = 275;

export const DATA = [
  {
    number: 0,
    title: 'Fundamentals',
    description:
      "This module fills in any gaps you may have, and ensures we're all working from the same solid foundation.",
    bullets: [
      'How browsers consume CSS',
      'Syntax and terminology',
      'Basics of colors, units, typography',
    ],
  },
  {
    number: 1,
    title: 'Rendering Logic I',
    description:
      'We pop the hood and see how the browser renders items in flow layout',
    bullets: [
      'Inheritance and the cascade',
      'The Box Model in depth',
      'The need-to-know quirks about Flow layout',
    ],
  },
  {
    number: 2,
    title: 'Rendering Logic II',
    description:
      "We continue our exploration of the browser's layout modes by examining positioned layout.",
    bullets: [
      'Understanding relative, absolute, fixed, and sticky positions',
      'Avoiding the z-index wars',
      'Managing overflow, hidden content',
    ],
  },
  {
    number: 3,
    title: 'Modern Component Architecture',
    description:
      'How does CSS fit into modern JS webapps? In this module, we learn how to take advantage of the JavaScript ecosystem.',
    bullets: [
      'Using tooling to solve specificity challenges without naming conventions',
      'How to build a component library from scratch',
      'How to use CSS-in-JS effectively',
    ],
  },
  {
    number: 4,
    title: 'Flexbox',
    description:
      'Flexbox brought an incredibly powerful layout algorithm to the web. We learn how to make the most of it.',
    bullets: [
      'Understanding grow, shrink, and basis',
      'A dozen common layouts with Flexbox',
      'Responsiveness without media queries',
    ],
  },
  {
    number: 5,
    title: 'Responsive and Functional CSS',
    description:
      "CSS has become a really dynamic language! We'll see how to use modern CSS features to build resilient layouts.",
    bullets: [
      'Building amazing mobile/tablet experiences',
      'Dynamic CSS with vw/vh, calc, min/max/clamp…',
      'The awesome power of CSS Variables',
    ],
  },
  {
    number: 6,
    title: 'Typography and Images',
    description:
      "The most important part of any webapp is the content! We'll learn how to wrangle text and media.",
    bullets: [
      'Responsive image optimization',
      'Web font best practices',
      'Variable fonts and the future of typography',
    ],
  },
  {
    number: 7,
    title: 'CSS Grid',
    description:
      "CSS Grid is a revolutionary layout system for the web. We'll see how to use it responsibly, to create next-level user interfaces.",
    bullets: [
      'When to use CSS Grid vs. Flexbox',
      'Understanding the layout algorithm at a deep level',
      'How to provide fallbacks for older browsers',
    ],
  },
  {
    number: 8,
    title: 'Animation and Interaction',
    description:
      "Animation is a vital part of all web applications. We'll learn how to build beautiful, lush animations.",
    bullets: [
      'Transition and animations from first principles',
      'How to design animations',
      'Animation performance',
    ],
  },
  {
    number: 9,
    title: 'Little Big Details',
    description:
      "In this jumbo-sized module, we'll cover a bunch of tips and tricks to create standout experiences on the web.",
    bullets: [
      'Aesthetics with gradients, box-shadows, clipping, and filters',
      'Optimizing the usability around scrolling / focus',
      'The details and polish to build next-level user experiences',
    ],
  },
];

export const CARD_POSITIONS = {
  0: {
    x: 16,
    y: 0,
  },
  1: {
    x: 5,
    y: 21,
  },
  2: {
    x: 38,
    y: 25,
  },
  3: {
    x: 71,
    y: 18,
  },
  4: {
    x: 70,
    y: 46,
  },
  5: {
    x: 40,
    y: 52,
  },
  6: {
    x: 10,
    y: 55,
  },
  7: {
    x: 5,
    y: 74,
  },
  8: {
    x: 37.5,
    y: 82,
  },
  9: {
    x: 70,
    y: 80,
  },
};
