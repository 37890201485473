import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import usePrefersReducedMotion from '@hooks/use-prefers-reduced-motion.hook';
import { detectBrowser } from '@helpers/browser.helpers';

import VisuallyHidden from '@components/VisuallyHidden';

function HeadingText({ ratio }) {
  const [displayTitle, setDisplayTitle] = React.useState(false);

  const prefersReducedMotion = usePrefersReducedMotion();

  const browser = detectBrowser();

  React.useEffect(() => {
    if (ratio > 0.5) {
      setDisplayTitle(true);
    } else if (ratio < 0.2) {
      setDisplayTitle(false);
    }
  }, [ratio]);

  const headingVisibility =
    displayTitle || prefersReducedMotion ? 1 : 0;
  const headingScale = displayTitle || prefersReducedMotion ? 1 : 0.5;
  const headingTranslate =
    displayTitle || prefersReducedMotion ? 0 : 100;
  const headingRotate =
    displayTitle || prefersReducedMotion ? 0 : -10;

  const style = useSpring({
    opacity: headingVisibility,
    transform: `
      translateY(${headingTranslate}px)
      scale(${headingScale})
      rotate(${headingRotate}deg)
    `,
    config: {
      tension: 300,
      friction: 75,
      precision: 0.001,
    },
    // For some reason, this text animation is choppy AF on Safari.
    immediate: browser === 'safari',
  });

  // On Safari, the text is invisible using the gradient.
  // Instead, I'll color each letter
  const HeadingComponent =
    browser === 'safari' ? SafariHeading : GradientHeading;

  const textContent = (
    <>
      <LineOne>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(38deg 97% 65%)',
            letterSpacing: '3px',
          }}
        >
          C
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(41deg 97% 67%)',
            letterSpacing: '0px',
          }}
        >
          S
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(46deg 96% 70%)',
            letterSpacing: '0px',
          }}
        >
          S
        </span>{' '}
        <Thin>
          <span
            data-letter={true}
            style={{
              '--c': 'hsl(48deg 95% 72%)',
              letterSpacing: '2px',
            }}
          >
            f
          </span>
          <span
            data-letter={true}
            style={{
              '--c': 'hsl(52deg 96% 80%)',
              letterSpacing: '1px',
            }}
          >
            o
          </span>
          <span
            data-letter={true}
            style={{
              '--c': 'hsl(54deg 97% 86%)',
              letterSpacing: '0px',
            }}
          >
            r
          </span>
        </Thin>
      </LineOne>
      <LineTwo>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(7deg 100% 65%)',
            letterSpacing: '-2px',
          }}
        >
          J
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(13deg 100% 66%)',
            letterSpacing: '-12px',
          }}
        >
          a
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(21deg 100% 66%)',
            letterSpacing: '-12px',
          }}
        >
          v
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(29deg 100% 65%)',
            letterSpacing: '3px',
          }}
        >
          a
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(37deg 100% 64%)',
            letterSpacing: '1px',
          }}
        >
          s
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(39deg 100% 65%)',
            letterSpacing: '4px',
          }}
        >
          c
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(44deg 100% 68%)',
            letterSpacing: '1px',
          }}
        >
          r
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(50deg 100% 71%)',
            letterSpacing: '0px',
          }}
        >
          i
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(53deg 100% 82%)',
            letterSpacing: '0px',
          }}
        >
          p
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(55deg 100% 95%)',
            letterSpacing: '0px',
          }}
        >
          t
        </span>
      </LineTwo>
      <LineThree>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(339deg 90% 56%)',
            letterSpacing: '-2px',
          }}
        >
          D
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(346deg 90% 59%)',
            letterSpacing: '1px',
          }}
        >
          e
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(355deg 92% 62%)',
            letterSpacing: '-2px',
          }}
        >
          v
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(5deg 92% 62%)',
            letterSpacing: '-3px',
          }}
        >
          e
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(9deg 94% 63%)',
            letterSpacing: '-3px',
          }}
        >
          l
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(16deg 96% 66%)',
            letterSpacing: '-2px',
          }}
        >
          o
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(27deg 96% 62%)',
            letterSpacing: '-2px',
          }}
        >
          p
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(34deg 98% 60%)',
            letterSpacing: '-3px',
          }}
        >
          e
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(37deg 99% 62%)',
            letterSpacing: '-2px',
          }}
        >
          r
        </span>
        <span
          data-letter={true}
          style={{
            '--c': 'hsl(41deg 100% 66%)',
            letterSpacing: '0px',
          }}
        >
          s
        </span>
      </LineThree>
    </>
  );

  return (
    <Wrapper>
      <HeadingComponent style={browser === 'safari' ? {} : style}>
        <VisuallyHidden>CSS for JavaScript Developers</VisuallyHidden>
        <span aria-hidden={true}>{textContent}</span>
      </HeadingComponent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const FlagWrapper = styled.div`
  position: absolute;
  right: 32px;
  top: 16px;

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    display: none;
  }
`;

const Heading = styled(animated.h1)`
  position: relative;
  text-transform: uppercase;
  line-height: 0.9;
  will-change: transform;
  font-kerning: none;
  color: hsl(36deg 100% 55%);
`;

const GradientHeading = styled(Heading)`
  color: initial;
  background-color: #ffb23e;
  background-image: linear-gradient(
    200.67deg,
    #ffffff 25%,
    hsl(54deg 100% 70%) 35%,
    hsl(36deg 100% 55%) 55.54%,
    hsl(333deg 100% 50%) 90%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

const SafariHeading = styled(Heading)`
  & [data-letter='true'] {
    color: var(--c);
  }
`;

const LineOne = styled.span`
  display: block;
  font-size: min(15vw, 106px);
`;
const LineTwo = styled.span`
  display: block;
  font-size: min(15vw, 106px);

  @media (max-width: 700px) {
    & > span {
      letter-spacing: initial !important;
    }
  }
`;
const LineThree = styled.span`
  display: block;
  font-size: min(14.5vw, 102px);
  /* TODO: Can I remove this? */
  letter-spacing: -0.1vw;
`;

const Thin = styled.span`
  font-weight: 300;
  font-size: 0.75em;
`;

export default HeadingText;
