/**
 * This hook is used on the main homepage to fetch the prices
 * to be shown on the purchase cards.
 *
 * It is NOT AT ALL DRY, because of stupid coupons.
 * DO NOT use this hook on the checkout page itself.
 */
import React from 'react';
import queryString from 'query-string';
import fetchJsonp from 'fetch-jsonp';

import {
  PADDLE_ENVIRONMENT,
  API_ROOT,
  PADDLE_API_ROOT,
} from '@constants';

function useProductPricing() {
  const [pricingInfo, setPricingInfo] = React.useState({
    countryCode: null,
    userEligibleForRegionalLicense: null,
    ineligibleReason: null,
    regionalLicenseRate: null,
    prices: null,
  });

  const [status, setStatus] = React.useState('loading');

  React.useEffect(() => {
    const queryParams = queryString.stringify({
      courseSlug: 'css-for-js',
      ...queryString.parse(window.location.search),
    });

    fetch(`${API_ROOT}/pricing/get-product-price?${queryParams}`)
      .then((res) => res.json())
      .then(
        ({
          countryCode,
          userEligibleForRegionalLicense,
          ineligibleReason,
          regionalLicenseRate,
          prices,
        }) => {
          // To help with debugging, I'll log the reason that a
          // user is ineligible to the console, when provided.
          if (!userEligibleForRegionalLicense && ineligibleReason) {
            console.info('Not eligible for regional pricing');
            console.info('Reason given:', ineligibleReason);
          }

          setPricingInfo({
            countryCode,
            userEligibleForRegionalLicense,
            ineligibleReason,
            regionalLicenseRate,
            prices,
          });

          setStatus('idle');
        }
      )
      .catch((err) => {
        console.error(
          'Issue retrieving price from the course platform API',
          err
        );
        setStatus('error');
      });
  }, []);

  return [pricingInfo, status];
}

export default useProductPricing;
