import React from 'react';
import styled from 'styled-components';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import Em from '@components/Em';

const Letter = () => {
  return (
    <Wrapper as="section">
      <StandoutText>
        CSS can be fun.{' '}
        <GoldGradientText>I promise.</GoldGradientText>
      </StandoutText>
      <p>
        For a lot of front-end developers, CSS is the most frustrating
        part of their work.
      </p>
      <p>
        When you make a mistake in TypeScript, you get a helpful
        tooltip telling you exactly what you did wrong. In CSS, by
        contrast, you're left in the dark, without any clues about why
        you're not getting the result you expected.
      </p>
      <p>
        These constant bewildering surprises take us out of flow
        state, and shake our confidence.{' '}
        <em>No wonder so many of us don't enjoy writing CSS!</em>
      </p>
      <p>
        Despite all appearances, though, CSS is actually a deeply
        consistent and robust language. It <em>feels</em>{' '}
        unpredictable because there are complex systems involved,
        systems that are totally invisible to us.
      </p>
      <ImplicitStandout>
        CSS is an <SlyEm>implicit language</SlyEm>.
      </ImplicitStandout>
      <p>
        It doesn't matter how many years you spend practicing CSS—if
        you don't learn how its underlying systems work, your mental
        model will always be incomplete.
      </p>
      <p>
        Think about how most of us learn CSS: we learn how individual
        properties work, instead of focusing on how layout algorithms
        use those properties as inputs. We learn “cool tricks” for
        specific situations, instead of concepts that can be applied
        broadly to <em>any</em> situation.
      </p>
      <p>
        <WithImage>
          <strong>
            It's like trying to solve a puzzle when you're missing
            pieces.
          </strong>
          <LongUnderline alt="" src="/long-underline.png" />
        </WithImage>{' '}
        Except it's even worse, since we blame ourselves!
      </p>
      <GoldStandout>
        <strong>Nobody is born with CSS skills.</strong>{' '}
      </GoldStandout>
      <p>
        We assume that we're missing some sort of “CSS gene”, that
        we're just not good at this stuff. And it's the most unfair
        self-criticism in the world. If you learn the rules of CSS,
        you can be good at it too!
      </p>

      <p>
        Becoming proficient with CSS is{' '}
        <WithImage>
          <em>game-changing</em>
          <DoubleUnderline alt="" src="/double-underline.png" />
        </WithImage>
        . All of a sudden, the most frustrating part of your job
        becomes fun! As JS developers, we spend so much energy trying
        to avoid writing CSS, when we could be embracing it, and using
        it to build world-class user interfaces.
      </p>

      <p>
        <strong>So how do you learn the rules of CSS?</strong> Well,
        you can spend countless hours spelunking through MDN
        documentation and CSSWG specifications, and then spend a few
        years practicing. That's what I did. It was undeniably
        effective—the end result is incredibly worthwhile—but the
        journey was long and arduous.
      </p>

      <p style={{ position: 'relative' }}>
        <StubbyArrow alt="" src="/stubby-arrow.png" />
        <HighlightedText>
          I want to help expedite that process for you.
        </HighlightedText>{' '}
        Over the past year, I've been packaging up all that knowledge
        and experience into a comprehensive self-paced online course.
      </p>
      <p>I call it…</p>
    </Wrapper>
  );
};

const Wrapper = styled(MaxWidthWrapper)`
  font-size: 20px;
  max-width: 700px;

  p:last-child {
    margin-bottom: 0;
  }
`;

const SlyEm = styled(Em)`
  color: var(--color-gray-700);
`;

const StandoutText = styled.p`
  font-size: 28px;
  margin-bottom: 32px;
`;

const ImplicitStandout = styled(StandoutText)`
  margin-top: 48px;
`;

const GoldGradientText = styled.strong`
  display: inline-block;
  background-color: #ffb23e;
  background-image: linear-gradient(
    -268.67deg,
    hsl(54deg 100% 70%),
    hsl(36deg 100% 55%)
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

const HighlightedText = styled.strong`
  position: relative;
  color: white;
  padding: 4px 8px;
  margin-left: -8px;
  margin-right: -4px;
  color: hsl(35deg 100% 60%);
`;

const GoldStandout = styled(StandoutText)`
  background-color: #ffb23e;
  background-image: linear-gradient(
    268.67deg,
    #ffffff 3.43%,
    hsl(54deg 100% 70%) 15.69%,
    hsl(36deg 100% 55%) 55.54%,
    hsl(333deg 100% 50%) 99%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

const WithImage = styled.span`
  position: relative;
  display: inline-block;
  /* prettier-ignore */
  text-shadow:
    0px 1px 1px hsl(274deg 16% 8% / 0.5),
    0px 1px 4px hsl(274deg 16% 8% / 0.5),
    0px 0px 6px hsl(274deg 16% 8%);

  @media (max-width: 710px) {
    display: inline;
  }
`;

const Adornment = styled.img`
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  bottom: 0;
  user-select: none;

  @media (max-width: 710px) {
    display: none;
  }
`;

const DoubleUnderline = styled(Adornment)`
  transform: translateY(40%);
  width: 75%;
  left: 0;
  right: 0;
  margin: auto;
`;
const LongUnderline = styled(Adornment)`
  transform: translateY(45%) translateX(-4px) rotate(0.5deg);
`;

const StubbyArrow = styled.img`
  display: block;
  position: absolute;
  z-index: -1;
  width: 30px;
  top: 0;
  left: 0;
  user-select: none;
  transform: translate(calc(-100% - 12px), 8px);

  @media (max-width: 800px) {
    display: none;
  }
`;

export default Letter;
