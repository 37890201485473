import React from 'react';
import styled, { keyframes } from 'styled-components';

import Modal from '@components/Modal';

function ConfirmModal({ email, handleDismiss }) {
  return (
    <Modal
      label="Confirm your email"
      theme="dark"
      maxWidth={725}
      handleDismiss={handleDismiss}
    >
      <Title>One More Step!</Title>
      <IntroParagraph>
        To appease the Email Deliverability gods, I'm afraid you'll
        need to click a confirmation link that was just sent to you
        via email.
      </IntroParagraph>
      <IntroParagraph>
        it should look something like this:
      </IntroParagraph>

      <EmailMockup>
        <tbody>
          <tr>
            <th scope="col">from</th>
            <td>me@joshwcomeau.com</td>
          </tr>
          <tr>
            <th scope="col">to</th>
            <td>{email}</td>
          </tr>
          <tr>
            <th scope="col">subject</th>
            <td>Important: Confirm your email ⚠️</td>
          </tr>
        </tbody>
      </EmailMockup>

      <FinalParagraph>
        If you don't see it, please check your “Promotions” tab / spam
        box. If you still can't find it,{' '}
        <a href="mailto:support@joshwcomeau.com">
          please let me know
        </a>
        .
      </FinalParagraph>
    </Modal>
  );
}

const Title = styled.h2`
  font-size: 4rem;
  text-align: center;
  margin-bottom: 32px;

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    font-size: 2.5rem;
  }
`;

const IntroParagraph = styled.p`
  font-size: 1.5rem;

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    font-size: 1.25rem;
  }
`;

const fade = keyframes`
  from {
    opacity: 0;
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-16px);
  }
`;

const EmailMockup = styled.table`
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 64px auto 0;
  padding: 16px 16px;
  border: 2px dashed var(--color-gray-700);
  border-radius: 4px;
  background: hsl(274deg 16% 8% / 0.6);
  animation: ${fade} 500ms;
  overflow: auto;

  tr:nth-of-type(1) {
    --duration: 800ms;
    --delay: 900ms;
  }
  tr:nth-of-type(2) {
    --duration: 950ms;
    --delay: 1100ms;
  }
  tr:nth-of-type(3) {
    --duration: 1500ms;
    --delay: 1500ms;
  }

  th {
    text-align: right;
    font-size: 1rem;
    color: hsl(223deg, 5%, 75%);
    padding: 0 16px;
  }

  td {
    font-size: 1.25rem;
    padding: 8px 0px;
  }

  th,
  td {
    animation: ${slideIn} var(--delay) var(--delay) both
      cubic-bezier(0.17, 0.67, 0.24, 1);
  }
`;

const FinalParagraph = styled.p`
  font-size: 1.125rem;
  margin: auto;
  margin-top: 64px;
  max-width: 550px;
  text-align: center;
`;

export default ConfirmModal;
