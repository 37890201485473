import React from 'react';
import styled from 'styled-components';

function Count({ unit, value }) {
  return (
    <Wrapper>
      <Value>{value}</Value>
      <Unit>
        {unit}
        {value === 1 ? '' : 's'}
      </Unit>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Value = styled.span`
  font-size: 1.5rem;
  font-weight: var(--font-weight-bold);
`;

const Unit = styled.span`
  font-size: 1rem;
`;

export default Count;
