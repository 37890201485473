import React from 'react';
import styled, { keyframes } from 'styled-components';

import useIntersectionRatio from '@hooks/use-intersection-ratio.hook';
import { detectBrowser } from '@helpers/browser.helpers';

import { GroundSwoop, GroundSwoopB } from '@components/Swoops';
import MaxWidthWrapper from '@components/MaxWidthWrapper';
import Spotlight from '@components/Spotlight';
import ClientOnly from '@components/ClientOnly';

import HeadingText from './HeadingText';

const Title = () => {
  const browser = detectBrowser();

  const wrapperRef = React.useRef();

  const ratio = useIntersectionRatio(wrapperRef);

  return (
    <Wrapper ref={wrapperRef}>
      <BackgroundSwoop color="hsl(267deg 50% 11%)" />
      <ClientOnly>
        <Spotlights browser={browser} />
      </ClientOnly>
      <ForegroundSwoop color="var(--color-background)" />
      <InnerWrapper
        style={{
          filter:
            browser === 'safari'
              ? undefined
              : 'drop-shadow(0px 4px 8px hsl(0deg 0% 0% / 0.4))',
        }}
      >
        <ClientOnly>
          <HeadingText ratio={ratio} />
        </ClientOnly>
      </InnerWrapper>
    </Wrapper>
  );
};

const Spotlights = React.memo(function Spotlights({ browser }) {
  /*
    Firefox REALLY doesn't handle spotlights well.
    Too much blurring.
  */
  if (browser === 'firefox') {
    return null;
  }

  return (
    <>
      <LeftSpotlight side="left" width="70vw" />
      <RightSpotlight side="right" width="60vw" />
    </>
  );
});

const Wrapper = styled.section`
  position: relative;
  isolation: isolate;
  height: 100vh;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    to bottom,
    hsl(265deg 40% 18% / 0),
    hsl(265deg 60% 18% / 0.95)
  );

  @media (max-width: 650px) {
    height: 250px;
    margin-top: 64px;
  }
`;

const InnerWrapper = styled(MaxWidthWrapper)`
  width: 100vw;
  padding-bottom: 64px;

  ::selection {
    background-color: #222 !important;
  }

  @media (min-width: 716px) {
    width: 700px;
  }
`;

const leftSpotlightAnimation = keyframes`
  from {
    transform: rotate(-25deg);
  }
  to {
    transform: rotate(10deg);
  }
`;

const LeftSpotlight = styled(Spotlight)`
  display: block;
  position: absolute;
  left: -5vw;
  bottom: -8vw;
  transform-origin: 25% 75%;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${leftSpotlightAnimation} 4567ms alternate ease-in-out
      infinite;
  }
`;
const rightSpotlightAnimation = keyframes`
  from {
    transform: rotate(-20deg);
  }
  to {
    transform: rotate(20deg);
  }
`;

const RightSpotlight = styled(Spotlight)`
  display: block;
  position: absolute;
  right: -5vw;
  bottom: -2vw;
  transform-origin: 75% 75%;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${rightSpotlightAnimation} 4000ms alternate ease-in-out
      infinite;
  }
`;

const ForegroundSwoop = styled(GroundSwoop)`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: -2px;
`;
const BackgroundSwoop = styled(GroundSwoopB)`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0px;
`;

export default Title;
