import React from 'react';
import styled, { keyframes } from 'styled-components';

function GoldTrophyTopper() {
  return (
    <Wrapper>
      <Topper>
        <BackgroundWrapper>
          <TopBackground />
        </BackgroundWrapper>
      </Topper>
      <TrophyWrapper>
        <Trophy alt="" src="/illustrations/gold-new.png" />
        <StarWrapper>
          <LeftStar
            alt=""
            src="/illustrations/left-star.png"
            style={{ maxWidth: 68 / 2 }}
          />
          <MiddleStar
            alt=""
            src="/illustrations/middle-star.png"
            style={{ maxWidth: 115 / 2 }}
          />
          <RightStar
            alt=""
            src="/illustrations/right-star.png"
            style={{ maxWidth: 68 / 2 }}
          />
        </StarWrapper>
      </TrophyWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  --top-inset: 8px;
`;

const Topper = styled.div`
  position: relative;
  width: calc(100% + 32px);
  margin-left: -16px;
  padding-top: 50%;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: var(--top-inset);
  left: var(--top-inset);
  right: var(--top-inset);
  height: 100%;
  border-radius: 0px 0px 16px 16px;
  /* Trim the corners */
  overflow: hidden;
`;

const TopBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from -90deg at 50% 45%,
    hsl(34deg 90% 45%),
    hsl(44deg 90% 75%) 12.5%,
    hsl(44deg 100% 90%) 25%,
    hsl(44deg 90% 75%) 37.5%,
    hsl(34deg 90% 45%) 50.5%
  );
  background-size: 100% 200%;
  border-radius: 1000px 1000px 0px 0px;
`;

const TrophyWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Trophy = styled.img`
  width: 100%;
  max-width: 325px;
  /* HACK Safari fix */
  max-height: 449px;
`;

const flutter = keyframes`
  from {
    transform: translateY(-10%);
  }
  to {
    transform: translateY(10%);
  }
`;
const higherFlutter = keyframes`
  from {
    transform: translateY(-25%);
  }
  to {
    transform: translateY(-10%);
  }
`;

const StarWrapper = styled.div`
  position: absolute;
  bottom: 62.5%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
  width: 100%;
`;

const Star = styled.img`
  display: block;
`;

const MiddleStar = styled(Star)`
  animation: ${higherFlutter} 2000ms ease-in-out infinite alternate;
`;

const LeftStar = styled(Star)`
  animation: ${flutter} 2000ms 250ms ease-in-out infinite alternate;
`;
const RightStar = styled(Star)`
  animation: ${flutter} 2000ms 500ms ease-in-out infinite alternate;
`;

export default GoldTrophyTopper;
