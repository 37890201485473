import React from 'react';
import styled from 'styled-components';
import formatDistance from 'date-fns/formatDistance';

import { FIRST_PURCHASE_BLOCK_ID } from '@constants';

import useIsOnSale from '@hooks/use-is-on-sale.hook';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import Spacer from '@components/Spacer';
import ClientOnly from '@components/ClientOnly';

import { SUPERHEADER_HEIGHT } from './Header.constants';
import Superheader from './Superheader';

const Header = ({ isAvailable }) => {
  const {
    isOnSale: showSuperheader,
    saleEnds,
    timeRemaining,
    isTwilightHour,
  } = useIsOnSale({ delay: 1000 });

  return (
    <>
      {showSuperheader && (
        <Superheader
          saleEnds={saleEnds}
          timeRemaining={timeRemaining}
          isTwilightHour={isTwilightHour}
        />
      )}
      <Wrapper
        style={{
          '--transition-duration': showSuperheader ? '500ms' : '0ms',
          '--top-offset': showSuperheader
            ? SUPERHEADER_HEIGHT
            : '0rem',
        }}
      >
        <InnerWrapper maxWidth={888}>
          <Actions>
            <LoginButton
              as="a"
              href="https://courses.joshwcomeau.com/"
            >
              Login
            </LoginButton>
            <Spacer $size={8} />
            {isAvailable && (
              <EnrollButton
                onClick={() => {
                  const element = document.querySelector(
                    `#${FIRST_PURCHASE_BLOCK_ID}`
                  );

                  // eslint-disable-next-line
                  element?.scrollIntoView({
                    behavior: 'smooth',
                  });
                }}
              >
                <BuyBackground />
                <Text>Enroll Now</Text>
              </EnrollButton>
            )}
          </Actions>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.header`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background: hsl(274deg 16% 8% / 0.965);
  transform: translateY(var(--top-offset));
  transition: transform var(--transition-duration)
    cubic-bezier(0.28, -0.03, 0.11, 1);
  will-change: transform;

  @supports (backdrop-filter: blur(10px)) {
    background: hsl(274deg 16% 8% / 0.5);
    backdrop-filter: blur(10px);
  }
`;

const InnerWrapper = styled(MaxWidthWrapper)`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding-top: 16px;
  padding-bottom: 16px;
  text-shadow: 0px 1px 3px hsl(0deg 0% 0% / 0.5);
`;

const Actions = styled.div`
  display: flex;
`;

const Button = styled.button`
  color: white;
  padding: 8px 20px;
  border: none;
  background: transparent;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
`;

const EnrollButton = styled(Button)`
  position: relative;
  text-shadow: 0px 1px 0px var(--color-buy-shadow);
  font-weight: 400;
`;

const BuyBackground = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-buy);
  border-radius: 6px;
  transition: transform 750ms;
  will-change: transform;

  ${EnrollButton}:hover & {
    transition: transform 150ms;
    transform: scale(1.05);
  }
  ${EnrollButton}:active & {
    transition: transform 0ms;
    transform: scale(1);
  }

  ${EnrollButton}:focus-visible & {
    box-shadow: 0px 0px 0px 2px #2960c5,
      0px 0px 0px 3px hsl(0deg 0% 100%);
  }
`;

const Text = styled.span`
  position: relative;
`;

const LoginButton = styled(Button)`
  font-weight: 300;
  opacity: 0.75;
  transition: opacity 400ms;

  &:hover {
    opacity: 1;
    text-decoration: none;
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 2px #2960c5,
      0px 0px 0px 3px hsl(0deg 0% 100%);
  }
`;

export default Header;
