import React from "react";
import styled from "styled-components";

import useWindowDimensions from "@hooks/use-window-dimensions.hook";
import useHasMounted from "@hooks/use-has-mounted.hook";

import ModuleCard from "./ModuleCard";
import IllustrationLayer from "./IllustrationLayer";
import { CARD_WIDTH, DATA, CARD_POSITIONS } from "./Curriculum.constants";

const VB_HEIGHT = 2250;
const VB_MAX_WIDTH = 1400;

function getPath(index, startPos, endPos, windowWidth, cardRef) {
  if (typeof cardRef.current === "undefined") {
    return;
  }

  const cardHeight = cardRef.current.getBoundingClientRect().height;

  const svgWidth = Math.min(windowWidth, VB_MAX_WIDTH);
  const startX = (startPos.x * svgWidth) / 100;
  const startY = (startPos.y * VB_HEIGHT) / 100;
  const endX = (endPos.x * svgWidth) / 100;
  const endY = (endPos.y * VB_HEIGHT) / 100;

  const BUFFER = 10;

  switch (index) {
    case 0: {
      return `
        M ${startX + CARD_WIDTH / 2} ${startY + cardHeight + BUFFER}
        C ${startX + CARD_WIDTH / 2} ${endY}
          ${endX + CARD_WIDTH / 2} ${startY + cardHeight}
          ${endX + CARD_WIDTH / 2} ${endY - BUFFER}
        `;
    }

    case 1: {
      const xMidpoint =
        startX + CARD_WIDTH + (endX - (startX + CARD_WIDTH)) / 2;
      return `
        M ${startX + CARD_WIDTH + BUFFER} ${startY + cardHeight * 0.8}
        Q ${xMidpoint} ${startY + cardHeight * 0.4}
          ${endX - BUFFER} ${endY + cardHeight * 0.2}
        `;
    }

    case 2: {
      return `
        M ${startX + CARD_WIDTH + BUFFER} ${startY + cardHeight * 0.75}
        C ${endX} ${startY + cardHeight * 0.4}
          ${startX + CARD_WIDTH + BUFFER} ${startY + cardHeight * 0.25}
          ${endX - BUFFER} ${endY + cardHeight * 0.25}
        `;
    }

    case 3: {
      // On large screens (1300px+), we'll do a fancy big swoop.
      // On smaller screens, we'll do something less ostentatious.
      const useBigSwoop = windowWidth > 1300;

      if (useBigSwoop) {
        const yMidpoint = endY - 26;
        const xLimit = CARD_WIDTH * (window.innerWidth * 0.001);

        return `
        M ${startX + CARD_WIDTH / 2} ${startY + cardHeight + BUFFER}
        C ${startX - CARD_WIDTH * 0.25} ${yMidpoint}
          ${startX + xLimit} ${startY + cardHeight + BUFFER}
          ${startX + xLimit} ${yMidpoint}
        Q ${endX + xLimit} ${endY + cardHeight * 0.25}
          ${endX + CARD_WIDTH + BUFFER} ${endY + cardHeight * 0.1}`;
      } else {
        return `
        M ${startX + CARD_WIDTH * 0.6} ${startY + cardHeight + BUFFER}
        C ${startX + CARD_WIDTH * 0.75} ${endY - BUFFER - 50}
          ${endX + CARD_WIDTH * 0.3} ${endY - BUFFER - 150}
          ${endX + CARD_WIDTH / 2} ${endY - BUFFER}
        `;
      }
    }

    case 4: {
      // prettier-ignore
      const endHeight = windowWidth < 1200
        ? endY + cardHeight * 0.15
        : endY + cardHeight * 0.1;

      return `
        M ${startX - BUFFER} ${startY + cardHeight * 0.9}
        C ${endX + CARD_WIDTH + 20} ${startY + cardHeight * 0.8}
          ${startX - 20} ${startY + cardHeight * 0.4}
          ${endX + CARD_WIDTH + BUFFER} ${endHeight}
        `;
    }

    case 5: {
      return `
        M ${startX - BUFFER} ${startY + cardHeight * 0.25}
        C ${endX - 100} ${endY - 200}
          ${endX + CARD_WIDTH + 200} ${endY - 300}
          ${endX + CARD_WIDTH * 0.75} ${endY - BUFFER}
        `;
    }

    case 6: {
      return `
        M ${startX + CARD_WIDTH / 2} ${startY + cardHeight + BUFFER}
        C ${startX + CARD_WIDTH / 2} ${endY}
          ${endX + CARD_WIDTH / 2} ${startY + cardHeight}
          ${endX + CARD_WIDTH / 2} ${endY - BUFFER}
        `;
    }

    case 7: {
      if (windowWidth < 1200) {
        return `
          M ${startX + CARD_WIDTH / 2} ${startY + cardHeight + BUFFER}
          C ${startX + CARD_WIDTH + 100} ${startY + cardHeight + 300}
            ${endX - 50} ${endY + cardHeight * 0.1}
            ${endX - BUFFER} ${endY + cardHeight * 0.1}
          `;
      }

      return `
        M ${startX + CARD_WIDTH / 2} ${startY + cardHeight + BUFFER}
        C ${startX + CARD_WIDTH + 100} ${startY + cardHeight + 300}
          ${endX - 150} ${endY + cardHeight * 0.1}
          ${endX - BUFFER} ${endY + cardHeight * 0.1}
        `;
    }

    case 8: {
      if (windowWidth < 1200) {
        return `
          M ${startX + CARD_WIDTH + BUFFER} ${startY + cardHeight * 0.75}
          C ${endX} ${startY + cardHeight * 0.75}
            ${startX + CARD_WIDTH * 1} ${endY + cardHeight * 0.15}
            ${endX - BUFFER} ${endY + cardHeight * 0.15}
          `;
      }

      return `
        M ${startX + CARD_WIDTH + BUFFER} ${startY + cardHeight * 0.75}
        C ${startX + CARD_WIDTH + 250} ${startY + cardHeight * 0.75}
          ${startX + CARD_WIDTH * 0.75} ${endY - 160}
          ${endX + CARD_WIDTH * 0.25} ${endY - BUFFER}
        `;
    }

    case 9: {
      return `
        M ${startX + CARD_WIDTH / 2} ${startY + cardHeight + BUFFER}
        C ${startX + CARD_WIDTH / 2} ${endY + 128}
          ${endX} ${startY + cardHeight + BUFFER}
          ${endX} ${endY + 128}
      `;
    }

    default: {
      return null;
    }
  }
}

function FancyPath() {
  const { width: windowWidth } = useWindowDimensions();
  const vbWidth = Math.min(windowWidth, VB_MAX_WIDTH);

  // Trigger a re-render after mount, to draw the paths now that
  // refs are collected.
  useHasMounted();

  const card0Ref = React.useRef();
  const card1Ref = React.useRef();
  const card2Ref = React.useRef();
  const card3Ref = React.useRef();
  const card4Ref = React.useRef();
  const card5Ref = React.useRef();
  const card6Ref = React.useRef();
  const card7Ref = React.useRef();
  const card8Ref = React.useRef();
  const card9Ref = React.useRef();

  const cardRefs = [
    card0Ref,
    card1Ref,
    card2Ref,
    card3Ref,
    card4Ref,
    card5Ref,
    card6Ref,
    card7Ref,
    card8Ref,
    card9Ref,
  ];

  return (
    <Wrapper>
      <IllustrationLayer />
      {typeof windowWidth === "number" && (
        <Svg
          viewBox={`0 0 ${vbWidth} ${VB_HEIGHT}`}
          preserveAspectRatio="none"
          fill="none"
        >
          {Object.values(CARD_POSITIONS).map((pos, index) => {
            let nextPosition = CARD_POSITIONS[index + 1];

            if (!nextPosition) {
              // For the very last line, we don't have a "next
              // card", but we do have a target; the heading
              // afterwards (“And so much more…”)
              nextPosition = {
                x: 50,
                y: 100,
              };
            }

            return (
              <Line
                key={index}
                d={getPath(
                  index,
                  pos,
                  nextPosition,
                  windowWidth,
                  cardRefs[index]
                )}
                fill="none"
              />
            );
          })}
        </Svg>
      )}

      {DATA.map((item, index) => (
        <PositionedModuleCard
          key={item.number}
          number={item.number}
          title={item.title}
          description={item.description}
          bullets={item.bullets}
          style={{
            top: CARD_POSITIONS[item.number].y + "%",
            left: CARD_POSITIONS[item.number].x + "%",
          }}
          ref={cardRefs[index]}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: ${VB_HEIGHT}px;
  margin-top: -400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 1400px;
  pointer-events: none;
  margin-bottom: 128px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Svg = styled.svg`
  display: block;
  overflow: visible;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Line = styled.path`
  stroke: var(--color-gray-700);
  stroke-width: 5px;
  stroke-dasharray: 10px 16px;
  stroke-linecap: round;
  stroke-linejoin: round;
  vector-effect: non-scaling-stroke;
  backface-visibility: hidden;
`;

const PositionedModuleCard = styled(ModuleCard)`
  position: absolute;
  width: ${CARD_WIDTH}px;
`;

export default FancyPath;
