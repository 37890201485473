import React from 'react';
import styled from 'styled-components';

import swoopyArrow from '@assets/arrows/arrow-pink-right.png';
import { detectBrowser } from '@helpers/browser.helpers';

import VideoPlayer from '@components/VideoPlayer';
import ClientOnly from '@components/ClientOnly';

const MainVideo = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <h2>
          Learn more in this <strong>short video</strong>
          <Arrow alt="" src={swoopyArrow} />
        </h2>
        <VideoWrapper>
          <ClientOnly>
            <GlowEffect src="/video-still.png?v=2" />
          </ClientOnly>
          <VideoWithShadow
            id="main-video"
            playbackId="521623380"
            aspectRatio={1500 / 2560}
          />
        </VideoWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

const GlowEffect = () => {
  const browser = detectBrowser();

  if (browser === 'safari') {
    return null;
  }

  return <Glow alt="" src="/video-still.png" />;
};

const Wrapper = styled.section`
  /* This is necessary to cover the bleeding light from the previous module. */
  position: relative;
  isolation: isolate;
  background: var(--color-background);
`;

const InnerWrapper = styled.div`
  max-width: 800px;
  margin: 0px auto;
  aspect-ratio: 800 / 541;

  h2 {
    position: relative;
    font-size: 21px;
    font-weight: 300;
    color: white;
    margin: 0 auto;
    margin-bottom: 42px;
    width: fit-content;

    strong {
      font-weight: 500;
    }
  }
`;

const Arrow = styled.img`
  display: block;
  position: absolute;
  left: -9px;
  bottom: -28px;
  width: 100px;
  transform: translateX(-100%) rotateY(180deg);

  @media (max-width: 545px) {
    display: none;
  }
`;

const Glow = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(40px);
`;

const VideoWrapper = styled.div`
  position: relative;
`;

const VideoWithShadow = styled(VideoPlayer)`
  /* prettier-ignore */
  box-shadow:
    0px 2px 15px hsl(274deg 16% 8% / 0.5),
    0px 2px 25px hsl(274deg 16% 8% / 0.25),
    0px 2px 50px hsl(274deg 16% 8% / 0.15) !important;
`;

export default MainVideo;
