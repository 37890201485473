import React from 'react';
import styled from 'styled-components';

import { clamp, normalize, throttle } from '@utils';
import usePrefersReducedMotion from '@hooks/use-prefers-reduced-motion.hook';

function IllustrationLayer() {
  const wrapperRef = React.useRef();
  const [scrollRatio, setScrollRatio] = React.useState(0.5);

  const prefersReducedMotion = usePrefersReducedMotion();

  React.useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    if (prefersReducedMotion) {
      setScrollRatio(0.5);
      return;
    }

    const handleScroll = throttle(function handleScroll(ev) {
      const bb = wrapperRef.current.getBoundingClientRect();

      // When the very top of our Wrapper is in the viewport,
      // its `top` will be equal to the window height.
      // This is the point we want parallax to start.
      const relevantMin = window.innerHeight;
      const relevantMax = bb.height * -1;

      const scrollRatio = clamp(
        normalize(bb.top, relevantMin, relevantMax, 0, 1),
        0,
        1
      );

      setScrollRatio(scrollRatio);
    }, 100);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prefersReducedMotion]);

  return (
    <Wrapper ref={wrapperRef}>
      {/* LAYER 1: CLOSE */}
      <Layer
        style={{
          transform: `translateY(${normalize(
            scrollRatio,
            0,
            1,
            -70,
            70
          )}px)`,
        }}
      >
        <Illustration
          src="/illustrations/03-lego-yellow.png"
          width={150 / 2}
          top={800}
          right={85}
        />
        <Illustration
          src="/illustrations/01-box-new.png"
          width={385 / 2}
          top={350}
          left={-10}
        />
        <Illustration
          src="/illustrations/05-phone.png"
          width={(324 / 2) * 0.75}
          top={1000}
          left={700}
        />
        <Illustration
          src="/illustrations/09-cupcake.png"
          width={328 / 2}
          bottom={-50}
          right={20}
        />
      </Layer>

      {/* LAYER 2: MID */}
      <Layer
        style={{
          transform: `translateY(${normalize(
            scrollRatio,
            0,
            1,
            -50,
            50
          )}px)`,
        }}
      >
        <Illustration
          src="/illustrations/03-lego-red.png"
          width={172 / 2}
          top={630}
          right={380}
        />
        <Illustration
          src="/illustrations/06-camera.png"
          width={200}
          top={1125}
          left={-45}
        />
        <Illustration
          src="/illustrations/08-stopwatch.png"
          width={262 / 2}
          top={1695}
          left={650}
        />
      </Layer>

      {/* LAYER 3: FAR */}
      <Layer
        style={{
          transform: `translateY(${normalize(
            scrollRatio,
            0,
            1,
            -25,
            25
          )}px)`,
        }}
      >
        <Illustration
          src="/illustrations/03-lego-orange.png"
          width={130 / 2}
          top={500}
          right={10}
        />

        <Illustration
          src="/illustrations/04-shoes.png"
          width={382 / 2}
          top={1320}
          right={20}
        />
        <Illustration
          src="/illustrations/07-bus.png"
          width={370 / 2}
          top={2000}
          left={-45}
        />
      </Layer>
    </Wrapper>
  );
}

const Illustration = ({
  src,
  top,
  left,
  right,
  bottom,
  width = 250,
}) => {
  return (
    <picture>
      <source
        type="image/avif"
        srcSet={src.replace('.png', '.avif')}
      />
      <Image
        alt=""
        src={src}
        style={{ top, left, right, bottom, width }}
      />
    </picture>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-width: 1400px) {
    display: none;
  }
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 200ms cubic-bezier(0.17, 0.67, 0.7, 1.07);
  will-change: transform;
`;

const Image = styled.img`
  position: absolute;
`;

export default IllustrationLayer;
