import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { detectBrowser } from '@helpers/browser.helpers';
import {
  getTimeRemaining,
  getTimeRemainingInHumanTerms,
  getIsTwilightHour,
} from '@reducers/countdown.reducer';

import RegionalDiscount from '@components/RegionalDiscount';
import TripleHills from '@components/TripleHills';
import Spinner from '@components/Spinner';
import Sparkles from '@components/Sparkles';
import Image from '@components/Image';

import UltimateCard from './UltimateCard';
import SideCard from './SideCard';
import Error from './Error';

const PurchaseSection = ({
  id,
  totalPurchases,
  productPricing,
  enableRegionalDiscount,
  toggleRegionalDiscount,
  productPricingStatus,
  saleEnds,
}) => {
  const [browser, setBrowser] = React.useState(null);

  const timeRemainingRaw = useSelector(getTimeRemaining);

  const isOnSale = timeRemainingRaw > 0;

  React.useEffect(() => {
    setBrowser(detectBrowser());
  }, []);

  if (productPricingStatus === 'error') {
    return <Error />;
  }

  const showRegionalLicenseWidget =
    productPricing.userEligibleForRegionalLicense &&
    productPricing.regionalLicenseRate > 0;

  // HACK: So when this component first renders, we
  // won't have any data from the API yet. But, I still
  // want to render the cards, even if I don't have
  // the prices!
  let sortedTiers;

  if (!productPricing.prices) {
    sortedTiers = [
      {
        tierSlug: 'basic',
        fullPrice: null,
        salePrice: null,
        tax: null,
      },
      {
        tierSlug: 'ultimate',
        fullPrice: null,
        salePrice: null,
        tax: null,
      },
      {
        tierSlug: 'pro',
        fullPrice: null,
        salePrice: null,
        tax: null,
      },
    ];
  } else {
    sortedTiers = ['basic', 'ultimate', 'pro'].reduce(
      (acc, tierSlug) => {
        return [...acc, productPricing.prices[tierSlug]];
      },
      []
    );
  }

  return (
    <Wrapper id={id}>
      <Backdrop>
        <TripleHills />
      </Backdrop>
      <HeadingLine />
      {isOnSale ? (
        <Heading>
          <Preheading>Holy smokes!</Preheading>
          It’s Black Friday!
        </Heading>
      ) : (
        <Heading>
          <Preheading>It’s time to</Preheading>
          Learn CSS
        </Heading>
      )}

      <Info className={browser !== 'safari' && 'not-safari'}>
        Join{' '}
        <strong>
          {typeof totalPurchases === 'string' ? (
            totalPurchases
          ) : (
            <Spinner size={16} color="hsl(45deg 100% 75%)" />
          )}{' '}
          developers
        </strong>{' '}
        who have changed their relationship with CSS by taking this
        course. Your journey begins today.
      </Info>
      {isOnSale && (
        <Info className={browser !== 'safari' && 'not-safari'}>
          This is the first sale since Black Friday 2022. You can save
          a <Sparkles>whopping 50%</Sparkles> on the course!
        </Info>
      )}

      {showRegionalLicenseWidget && (
        <RegionalWrapper>
          <RegionalDiscount
            isEnabled={enableRegionalDiscount}
            toggleIsEnabled={toggleRegionalDiscount}
            country={productPricing.countryCode}
            discountRate={productPricing.regionalLicenseRate}
          />
        </RegionalWrapper>
      )}

      <CardsOuterWrapper>
        {/* <Balloons src="/illustrations/balloons.png?v=2" /> */}
        <CardsWrapper>
          {sortedTiers.map(
            ({
              tierSlug,
              tierCanBeRegionallyLicensed,
              ...delegated
            }) => {
              const Component =
                tierSlug === 'ultimate' ? UltimateCard : SideCard;

              const isAvailable =
                tierCanBeRegionallyLicensed ||
                !enableRegionalDiscount;

              return (
                <Component
                  key={tierSlug}
                  tier={tierSlug}
                  isLoadingPrice={productPricingStatus === 'loading'}
                  saleEnds={saleEnds}
                  isAvailable={isAvailable}
                  pricingInfo={delegated}
                  enableRegionalDiscount={enableRegionalDiscount}
                />
              );
            }
          )}
        </CardsWrapper>
      </CardsOuterWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  scroll-margin-top: 128px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 128px;
`;

const Heading = styled.h2`
  position: relative;
  text-align: center;
  font-size: min(64px, 10vw);
  font-weight: 500;
  width: max-content;
  margin: 0 auto;
  padding: 0 32px;
  background: var(--color-background);
`;

const HeadingLine = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  height: 1px;
  background: white;
  top: 82px;

  @media (max-width: 750px) {
    display: none;
  }
`;

const Preheading = styled.span`
  display: block;
  font-size: 21px;
  color: var(--color-gray-700);
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 300;
`;

const Info = styled.p`
  position: relative;
  z-index: 2;
  font-size: 1.25rem;
  max-width: 450px;
  margin: 32px auto;
  text-align: center;
  padding: 0 16px;

  & b {
    font-weight: var(--font-weight-medium);
  }

  & strong {
    color: hsl(45deg 100% 65%);
  }

  &.not-safari strong {
    background-color: #ffb23e;
    background-image: linear-gradient(
      182deg,
      #ffffff 15%,
      hsl(54deg 100% 70%),
      hsl(36deg 100% 55%)
    );
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
`;

const CardsOuterWrapper = styled.div`
  position: relative;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.25fr 1fr;
  grid-template-areas: 'basic ultimate pro';
  justify-content: center;
  align-items: flex-end;
  grid-gap: 32px;
  padding: 256px 16px 0;
  max-width: 1200px;
  margin: 0 auto;
  scroll-margin-top: 100px;

  @media (max-width: 1080px) {
    grid-gap: 16px;
  }

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'ultimate ultimate'
      'pro basic';
    justify-items: center;
    align-items: start;
    grid-gap: 148px 32px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'ultimate'
      'pro'
      'basic';
    grid-gap: 148px;
  }
`;

// const Balloons = styled(Image)`
//   position: absolute;
//   top: -110px;
//   left: 0;
//   right: 0;
//   margin: auto;
//   width: 822px;
//   max-width: 100%;

//   @media (max-width: 650px) {
//     top: 0;
//   }
//   @media (max-width: 500px) {
//     display: none;
//   }
// `;

const Backdrop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 350px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0.5;
  /* prettier-ignore */
  background: linear-gradient(
    to bottom,
    hsl(40deg 40% 30% / 0),
    hsl(40deg 60% 30% / 0.95)
  );
`;

const RegionalWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-width: 650px;
  margin: 96px auto 0;
`;

export default PurchaseSection;
