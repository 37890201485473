import React from 'react';
import styled from 'styled-components';

import { COURSE_PLATFORM_ROOT } from '@constants';

import Spacer from '@components/Spacer';
import Spinner from '@components/Spinner';
import BuyButton from '@components/BuyButton';
import LaunchBanner from '@components/LaunchBanner';

import GoldTrophyTopper from './GoldTrophyTopper';
import Checklist from './Checklist';
import InfoTooltip from './InfoTooltip';
import PriceDisplay from './PriceDisplay';
import IncludedModules from './IncludedModules';

function UltimateCard({
  isLoadingPrice,
  pricingInfo,
  enableRegionalDiscount,
}) {
  let href = `${COURSE_PLATFORM_ROOT}/checkout/css-for-js/ultimate`;

  if (enableRegionalDiscount) {
    href += '?regionalLicenseRequested=true';
  }

  return (
    <OuterWrapper>
      <Wrapper>
        <GoldTrophyTopper />

        <Title>
          <Prefix>The</Prefix>
          Ultimate Package
        </Title>

        <Spacer $size={16} />

        <Summary>
          Develop a deep intuition for the entire CSS language.
          Includes <strong>all 10 modules</strong> and exclusive bonus
          content!
        </Summary>

        {isLoadingPrice ? (
          <Spinner />
        ) : (
          <>
            <PriceDisplay
              pricingInfo={pricingInfo}
              enableRegionalDiscount={enableRegionalDiscount}
            />
          </>
        )}

        <Actions>
          <BuyButton href={href}>Register Now</BuyButton>
        </Actions>

        <Spacer $size={16} />
        <Footnote>Price shown in USD, excluding sales tax.</Footnote>

        <UltimateLaunchBanner />

        <Spacer $size={32} />

        <Checklist
          items={[
            <span>
              <strong>Full access to all 10 modules</strong>
            </span>,
            <span>
              <strong>Lifetime access</strong> to all course materials
            </span>,
            <span>Exclusive Discord community</span>,
            <span>30-day money-back guarantee</span>,
            <span>Certificate of Completion</span>,
            <span>
              <strong>Bonus: </strong> Video Archive{' '}
              <InfoTooltip>
                A continuously-updated video library, covering answers
                to student questions and build walkthroughs, showing
                how to build particularly tricky UIs.
              </InfoTooltip>
            </span>,
            <span>
              <strong>Bonus: </strong> Resource treasure trove{' '}
              <InfoTooltip>
                A curated set of tools that I use, with detailed
                guides for getting the most out of them.
              </InfoTooltip>
            </span>,
          ]}
        />

        <Spacer $size={32} />

        <IncludedModules num={10} />
      </Wrapper>
    </OuterWrapper>
  );
}

const OuterWrapper = styled.div`
  grid-area: ultimate;
  position: relative;
  --selection-background-color: hsl(0deg 0% 10% / 0.75);
  --selection-text-color: hsl(0deg, 0%, 100%);
  color-scheme: light;
  max-width: 500px;

  @media (max-width: 528px) {
    padding-top: 0px;
  }
`;

const Wrapper = styled.article`
  position: relative;
  padding: 16px;
  padding-top: 0;
  /*
    HACK: Since I pick such a big number for the top, I need to
    also inflate the bottom
  */
  border-radius: 500px 500px 16px 16px;
  background: white;
  display: flex;
  flex-direction: column;
  color: hsl(223deg, 10%, 20%);
  box-shadow: 0px 0px 20px hsl(0deg 0% 0% / 0.5);
`;

const Summary = styled.p`
  font-size: 1.125rem;
  text-align: center;
`;

const Divider = styled.div`
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid var(--color-gray-300);
`;

const Title = styled.h3`
  position: relative;
  font-size: 28px;
  font-weight: 500;
  align-self: center;
  color: black;
  margin-top: 32px;
`;

const Prefix = styled.span`
  display: block;
  font-size: 0.666em;
  font-weight: 300;
  color: hsl(223deg, 10%, 40%);
  text-align: center;
  margin-bottom: -8px;
`;

const Actions = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const Footnote = styled.p`
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 0;
`;

const UltimateLaunchBanner = styled(LaunchBanner)`
  margin-top: 16px;
`;

export default UltimateCard;
