import React from 'react';
import styled from 'styled-components';

import InlineCode from '@components/InlineCode';
import Link from '@components/Link';

const List = styled.ul`
  margin-bottom: 32px;
`;

export default [
  {
    slug: 'regional-licenses',
    q:
      'Do you support Purchasing Power Parity (PPP) / regional licenses?',
    a: (
      <>
        <p>
          Yes! If you visit this page from a country with
          significantly lower purchasing power, you should see a box
          that allows you to select a regional license at a lower
          price.
        </p>

        <p>
          If you don't see the box and you're using a VPN, please try
          disconnecting the VPN.
        </p>
      </>
    ),
  },
  {
    slug: 'what-is-for-javascript',
    q: 'What does “for JavaScript developers” mean?',
    a: (
      <>
        <p>
          This course is specifically built for folks who use a
          component-based JS framework like React, Angular, or Vue.
        </p>

        <p>This affects things in a number of ways:</p>

        <List>
          <li>
            We focus on the subset of CSS that matters most to JS
            developers. For example, we don't cover naming
            methodologies like BEM because modern frameworks have
            tools that manage scoping for us.
          </li>
          <li>
            As JS developers, we have concerns that other CSS
            developers don't have: things like working with CSS-in-JS,
            or building component libraries from scratch. We cover
            them in this course!
          </li>
          <li>
            We look at real-world examples, CSS being used in the
            context of React applications.
          </li>
          <li>
            I use your familiarity with JS to help make it easier to
            learn CSS! For example, if you understand how the spread
            operator (<InlineCode>...</InlineCode>) works, you already
            understand how the cascade works! I explain the
            similarities in the course.
          </li>
        </List>

        <p>
          I wanted to make a CSS course specifically for JS developers
          because I know how frustrating CSS can be for us! I
          struggled with it myself for years and years.
        </p>

        <p>
          You don't need to be a JavaScript expert, but I do assume
          that you have some experience with a JS framework like
          React/Angular/Vue.
        </p>
        <p>
          If you haven't used a JS framework, you'll still learn a lot
          about CSS fundamentals in this course, though some of the
          workshops will be more challenging.
        </p>
      </>
    ),
  },
  {
    slug: 'convince-manager',
    q: 'How do I convince my manager to buy this course for me?',
    a: (
      <>
        <p>
          Employers want to invest in resources that help their team
          deliver better results more quickly. I believe CSS for
          JavaScript Developers is an <em>incredibly</em> worthwhile
          investment.
        </p>

        <p>
          If you work as a software developer and aren't sure how to
          get your employer to put this course on the company card, I
          created a letter template you can use to help persuade them
          to pick up a copy for you.
        </p>

        <p>
          Check it out:
          <br />
          <Link href="https://courses.joshwcomeau.com/letter-to-your-manager">
            Letter To Your Manager
          </Link>
        </p>
      </>
    ),
  },
  {
    slug: 'team-licenses',
    q: 'Can I purchase multiple licenses, for my team or group?',
    a: (
      <>
        <p>
          Absolutely! You can order multiple licenses on the{' '}
          <Link href="/teams">Teams order page</Link>.
        </p>

        <p>
          Depending on the number of licenses you purchase, a 10-20%
          discount will be applied automatically (requires at least 4
          licenses to qualify).
        </p>

        <p>
          You'll also gain access to the Teams Administration
          dashboard. This feature is under active development, but it
          will soon allow you to easily add and manage licenses.
        </p>
      </>
    ),
  },
  {
    slug: 'student-discount',
    q: 'Is there a student discount?',
    a: (
      <>
        <p>There is indeed!</p>

        <p>
          If you're actively enrolled in an educational program, you
          can receive <strong>20% off</strong> the full cost of the
          course (the “Ultimate” package). This discount can't be
          combined with any other sales or discounts, but it{' '}
          <em>can</em> be combined with regional licenses.
        </p>

        <p>
          This discount is intended for full-time or part-time
          students, including coding bootcamps like General Assembly.
        </p>

        <p>
          To receive a unique coupon code, shoot an email to{' '}
          <a href="mailto:support@joshwcomeau.com">
            support@joshwcomeau.com
          </a>{' '}
          with proof of enrolment.
        </p>
      </>
    ),
  },
  {
    slug: 'subscription-expiration',
    q: 'Is this a subscription? Does the license expire?',
    a: (
      <>
        <p>
          Nope! It's a one-time purchase. When you buy{' '}
          <em>CSS for JavaScript Developers</em>, you'll have access
          to the course for the lifetime of my course platform. Not
          only that, but you'll get all updates and improvements for
          free. I plan on improving this course quite a lot over the
          next couple years!
        </p>
      </>
    ),
  },
  {
    slug: 'interactive-course',
    q:
      'Are there assignments and projects? Or is this mostly a video course?',
    a: (
      <>
        <p>
          I believe that in order to learn something, you need to get
          your hands dirty. This course offers <em>a ton</em> of
          opportunities to practice the concepts we learn!
        </p>
        <p>
          Throughout each module, there are exercises, small
          challenges, and interactive demos. Most modules end in a
          workshop, a larger project where we can apply the skills
          we've learned in a broader, more-realistic context.
        </p>
        <p>
          That said, there are over 170 bite-sized videos in the
          course as well. The general format is that each lesson will
          mix written and video content to explain the concept, and
          then there are opportunities for practice and
          experimentation, leading up to the workshop at the end of
          the module.
        </p>
      </>
    ),
  },
  {
    slug: 'upgrade-tiers',
    q: 'Can I upgrade later if I buy a lower tier now?',
    a: (
      <>
        <p>
          Yes — if you purchase either the "Basic" or the "Pro"
          package, you'll be able to upgrade your purchase to the
          "Ultimate" package later on.
        </p>
        <p>
          The upgrade cost will be the difference between what you
          paid originally and the current <em>full</em> cost of the
          Ultimate package. Any future discounts or sales cannot be
          applied to upgrades.
        </p>
      </>
    ),
  },
  {
    slug: 'refund-policy',
    q: "What if I don't like the course? Is there a refund policy?",
    a: (
      <>
        <p>
          If you're not happy with the course, for any reason, you can{' '}
          <a href="mailto:support@joshwcomeau.com">
            reach out by email
          </a>{' '}
          in the first 30 days and I'll refund your purchase,
          unconditionally.
        </p>
      </>
    ),
  },
  {
    slug: 'accessibility',
    q: 'Is this course accessible?',
    a: (
      <>
        <p>
          I've done my best to build an accessible product, but it's
          an area of constant improvement.
        </p>

        <p>
          In terms of navigation, the course platform and all of its
          exercises, minigames, and interactive elements should be
          controllable through a keyboard alone, no mouse required. As
          it happens, the early days of the course development were
          done exclusively through dictation and eye-tracking, no
          mouse or keyboard.
        </p>

        <p>
          In terms of color, all text and UI elements should have
          AA-level contrast or higher. I've also done my best to
          support colorblind folks, by never communicating anything
          exclusively through color/hue.
        </p>

        <p>All videos include closed-captions in English.</p>

        <p>
          For folks with little or no eyesight, I've struggled to
          imagine how I could build a quality course on CSS, given
          CSS' visual nature. I'm sad to say that it's a challenge I
          have not yet solved.
        </p>
      </>
    ),
  },
  {
    slug: 'already-comfy',
    q:
      'I already feel pretty comfortable with CSS! Will I still learn a lot in this course?',
    a: (
      <>
        <p>Absolutely!</p>
        <p>
          I've heard from so many developers now who have said
          something along the lines of “I've been writing CSS for 10+
          years, and I've aleady learned <em>so much</em>.”
        </p>
        <p>
          CSS is an incredibly rich language, and this course aims to
          give you a deeper understanding. Even if you've used CSS for
          a long time, there are mechanisms that can only be learned
          through research, and that knowledge has direct tangible
          improvements on the writing experience.
        </p>
        <p>
          I'd recommend checking out these two blog posts. They're
          repackaged from the course. If you learn something from
          them, I expect you'll get a lot out of this course:
        </p>
        <List>
          <li>
            <a href="https://www.joshwcomeau.com/css/stacking-contexts/">
              What the Heck, z-index??
            </a>
          </li>
          <li>
            <a href="https://www.joshwcomeau.com/css/rules-of-margin-collapse/">
              The Rules of Margin Collapse
            </a>
          </li>
        </List>
      </>
    ),
  },
  {
    slug: 'beginner-friendly',
    q: 'Is this course beginner-friendly?',
    a: (
      <>
        <p>
          It depends on what you mean by "beginner"{' '}
          <span role="img" aria-label="Smiling with sweat bead">
            😅
          </span>
        </p>
        <p>
          This course is <em>not</em> intended for absolute beginners.
          If you've never written any CSS before, this isn't the right
          course for you.
        </p>
        <p>
          I also assume that you have some experience with a JS
          framework like React. You definitely don't need to be an
          expert, but you should be comfortable with the idea of
          components, and passing data between them (eg. through
          props).
        </p>
        <p>
          That said, if you've been building web applications for a
          couple months, or have just graduated from a coding
          bootcamp, this course is perfect for you.
        </p>
        <p>Here are some things I assume you know:</p>
        <List>
          <li>
            You understand CSS syntax like{' '}
            <InlineCode>{`p { color: red; }`}</InlineCode>
          </li>
          <li>You've used a JS framework like React</li>
          <li>
            You know how to use a terminal to run Node/NPM projects (I
            do review this one briefly in the course though!)
          </li>
        </List>
        <p>Knowledge of Git is beneficial, but not required.</p>
      </>
    ),
  },
  {
    slug: 'react-only',
    q: 'Is this course only for React developers?',
    a: (
      <>
        <p>
          Definitely not! Though I <em>do</em> expect you to have at
          least some experience with a component-based JavaScript
          framework (Angular, Vue, Svelte…).
        </p>

        <p>
          Certain modules and workshops do use React, since I want to
          explain the concepts “in context”, in real-world situations.{' '}
          <strong>
            All of the React-specific stuff comes preloaded, though
          </strong>
          . You won't have to mess with state or any advanced React
          features. You <em>will</em> need to write some JSX, but I'm
          confident you won't struggle with it if you're familiar with
          another framework.
        </p>

        <p>
          I created a 15-minute lesson specifically to help non-React
          developers learn "just enough React" to complete the
          workshops in this course.
        </p>
      </>
    ),
  },
  {
    slug: 'discord',
    q: 'Is there a Slack or Discord group?',
    a: (
      <>
        <p>
          Yes! We're using Discord for our online community. The
          community is already vibrant and mature, because of our
          Early Access period. Lots of great discussion happens there
          every day!
        </p>
        <p>
          The community is moderated according to our{' '}
          <a href="https://courses.joshwcomeau.com/code-of-conduct">
            Code of Conduct
          </a>
          .
        </p>
      </>
    ),
  },
  {
    slug: 'asking-questions',
    q: 'What if I get stuck? Can I ask questions?',
    a: (
      <>
        <p>Absolutely!</p>

        <p>
          This course uses Discord as our community platform. There is
          a channel specifically for getting help with the content in
          this course, and you can also ask general CSS / web
          development questions!
        </p>
        <p>
          I've been spending a lot of time answering your questions on
          Discord, but I've been thrilled to see that the community
          has been really active as well.
        </p>
      </>
    ),
  },
  {
    slug: 'right-for-me',
    q: 'Is this course right for me?',
    a: (
      <>
        <p>
          This course is built for JavaScript developers who wish they
          found CSS less frustrating.
        </p>
        <p>
          Here are some examples of the kinds of people I think would
          benefit the most from <em>CSS for JavaScript Developers</em>
          :
        </p>
        <List>
          <li>
            React/Angular/Vue/Svelte developers who feel their
            productivity hit a wall when they have to take on a CSS
            task.
          </li>
          <li>
            Recent graduates (Bootcamp <em>or</em> college) looking to
            level-up their skills as they embark on their new careers.
          </li>
          <li>
            Full-stack / Backend devs who want to invest more in their
            front-end skillset.
          </li>
          <li>
            Early-career devs hoping to get better and faster at
            building top-notch user interfaces.
          </li>
          <li>
            Experienced front-end developers who feel OK with CSS, but
            still find themselves getting tripped up by it from time
            to time.
          </li>
          <li>
            Developers looking to modernize their outdated CSS
            knowledge.
          </li>
        </List>
        <p>
          <em>CSS for JavaScript Developers</em> might{' '}
          <strong>not</strong> be a good fit for you if you fit one of
          the following categories:
        </p>
        <List>
          <li>
            You're brand-new to front-end development, and are still
            picking up the fundamentals of CSS and JavaScript.
          </li>
          <li>
            You've never worked with a component-based JS framework
            like React, Vue, Angular, Ember, Svelte…
          </li>
          <li>
            You're hoping to learn design skills. This course does
            cover some design principles, but it's primarily about
            using CSS to implement pre-existing designs.
          </li>
          <li>
            You're not serious about learning CSS. If you're mostly
            looking for a few quick tips and neat tricks, you can find
            those on Twitter for free!
          </li>
        </List>
      </>
    ),
  },
  {
    slug: 'certificate',
    q: 'Do you offer a certificate of completion?',
    a: (
      <>
        <p>
          Yep! Anyone who completes the full course will be able to
          save a <em>.pdf</em> with their name on it, showing that
          they've completed the CSS for JavaScript Developers course.
          These certificates can also be verified, as each one has a
          unique URL!
        </p>

        <p>
          This can be useful to claim reimbursement from your
          employer, to share on social media, or to just feel great
          about your accomplishment!
        </p>
      </>
    ),
  },
  {
    slug: 'receipt',
    q: 'What about a receipt, so I can be reimbursed?',
    a: (
      <>
        <p>
          Right after purchasing, you'll be emailed a receipt/invoice
          from Paddle. You'll be able to add whatever name and address
          is necessary to facilitate a reimbursement.
        </p>

        <p>
          You can also add your VAT number, to have any VAT charges
          reimbursed.
        </p>
      </>
    ),
  },
  // {
  //   q: "Can I gift a license to somebody else?",
  //   a: (
  //     <>
  //       <p>
  //         I don't have a formal process for this, but I definitely want to
  //         support it! For the Early Access launch, there are two options:
  //       </p>
  //       <List>
  //         <li>You can enter the recipient's email in the checkout process.</li>
  //         <li>
  //           You can purchase for yourself, and then email me with the name/email
  //           of the person you'd like to gift it to.
  //         </li>
  //       </List>
  //       <p>
  //         The first option is quicker, but please note that the recipient will{" "}
  //         <em>also</em> receive a copy of the invoice, with the last 4 digits of
  //         your credit card (if paying by credit). The second option preserves
  //         your privacy, but it may take me a day or two to respond.
  //       </p>
  //     </>
  //   ),
  // },
  {
    slug: 'time-commitment',
    q: 'How long will it take to complete the course?',
    a: (
      <>
        <p>
          It's hard to say! There is <em>a lot</em> of content, but
          it's structured in such a way that it's easy to skip stuff
          that you already know.
        </p>
        <p>
          Based on early feedback, most modules will take between 2
          and 6 hours to complete, depending on how "thoroughly" you
          aim to complete them. There are 10 modules, so I expect on
          average it will take 40 hours to complete.
        </p>
      </>
    ),
  },
  {
    slug: 'miscellaneous',
    q: 'I have a question not listed here!',
    a: (
      <>
        <p>
          I can be reached at{' '}
          <a href="mailto:support@joshwcomeau.com">
            support@joshwcomeau.com
          </a>
          ! Feel free to send any questions you have.
        </p>
      </>
    ),
  },
];
