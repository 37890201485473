import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import {
  API_ROOT,
  VISITOR_ID_KEY,
  FIRST_PURCHASE_BLOCK_ID,
} from '@constants';

import useProductPricing from '@hooks/use-product-pricing.hook';
import useToggle from '@hooks/use-toggle.hook';
import { getTestGroupStatus } from '@helpers/ab-test.helpers';
import { addCommasToNumber } from '@utils';

import Hero from '@components/Hero';
import HeroWithSignup from '@components/Hero/HeroWithSignup';
import Header from '@components/Header';
import Title from '@components/Title';
import Letter from '@components/Letter';
import CompanyLogos from '@components/CompanyLogos';
import SEO from '@components/SEO/SEO';
import MainVideo from '@components/MainVideo';
import Curriculum from '@components/Curriculum';
import PurchaseSection from '@components/PurchaseSection';
import AboutMe from '@components/AboutMe';
import TeamLicenseBlurb from '@components/TeamLicenseBlurb';
import Faq from '@components/Faq';
import Testimonial from '@components/Testimonial';
import DoubleTestimonial from '@components/DoubleTestimonial';
import TestimonialContent from '@components/TestimonialContent';
import LastChanceSignupForm from '@components/LastChanceSignupForm';
import Footer from '@components/Footer';
import Spacer from '@components/Spacer';
import MaxWidthWrapper from '@components/MaxWidthWrapper';
import ClientOnly from '@components/ClientOnly';
import WallOfLove from '@components/WallOfLove';

const IndexPage = ({ location, navigate }) => {
  const [isInTestGroup, setIsInTestGroup] = React.useState(false);
  const [enableRegionalDiscount, toggleRegionalDiscount] = useToggle(
    false
  );
  const [totalPurchases, setTotalPurchases] = React.useState(null);
  const [productPricing, productPricingStatus] = useProductPricing();

  const [
    joinedEmailCourseThroughForm,
    setJoinedEmailCourseThroughForm,
  ] = React.useState(null);

  React.useEffect(() => {
    // Store the custom checkout URL in session storage,
    // so that I can load it if the user follows a link
    // to that page.
    if (productPricing?.prices && enableRegionalDiscount) {
      window.sessionStorage.setItem(
        'productPricing',
        JSON.stringify(productPricing)
      );
    } else {
      window.sessionStorage.removeItem('productPricing');
    }
  }, [productPricing, enableRegionalDiscount]);

  // On page load, get the # of purchases
  React.useEffect(() => {
    const isInTestGroup = getTestGroupStatus();
    setIsInTestGroup(isInTestGroup);

    async function getCount() {
      try {
        const q = queryString.parse(window.location.search);

        let fetchUrl = `${API_ROOT}/purchases/get-count?courseSlug=css-for-js&isInTestGroup=${isInTestGroup}`;

        if (q.from) {
          fetchUrl = `${fetchUrl}&from=${q.from}`;
        }

        const res = await fetch(fetchUrl);
        const json = await res.json();

        if (typeof json.visitorId === 'string') {
          window.localStorage.setItem(VISITOR_ID_KEY, json.visitorId);
        }

        if (typeof json.count === 'number') {
          const formattedNumber = addCommasToNumber(json.count);
          setTotalPurchases(formattedNumber);
        } else {
          throw json;
        }
      } catch (err) {
        console.error(err);
        setTotalPurchases('thousands of');
      }
    }

    getCount();
  }, []);

  return (
    <Wrapper>
      <Header isAvailable />
      {isInTestGroup &&
      joinedEmailCourseThroughForm !== 'last-chance' ? (
        <HeroWithSignup
          handleSignup={() => setJoinedEmailCourseThroughForm('hero')}
        />
      ) : (
        <Hero hasLaunched />
      )}
      <Spacer $size={64} />
      <Clamped>
        <Letter />
        <Title />
      </Clamped>
      <Spacer $size={128} />
      <CompanyLogos />
      <Spacer $size={128} />
      <DoubleTestimonial>
        <TestimonialContent
          authorName="Kent C. Dodds"
          authorTitle="Instructor and Author, EpicReact"
          authorAvatar="/avatars/kentcdodds.jpg"
          href="https://twitter.com/kentcdodds"
          type="double"
          quoteStyle={{ padding: '0px 10px' }}
        >
          As a educator myself who's made it my life's work to
          effectively help people learn and retain what they're
          learning,{' '}
          <strong>
            I'm extremely impressed by Josh's approach to teaching.
          </strong>{' '}
          It's abundantly clear that Josh knows what he's doing.
          Students will come away from this course with the skills
          they need to be more productive at CSS,{' '}
          <strong>
            paying back their investment many times over.
          </strong>
        </TestimonialContent>
        <TestimonialContent
          authorName="Laurie Barth"
          authorTitle="Senior Software Engineer, Netflix"
          authorAvatar="/avatars/laurieontech.jpg"
          href="https://twitter.com/laurieontech/status/1368294781684695042"
          type="double"
        >
          I had seriously high expectations for Josh’s CSS course. And
          honestly? <strong>It's exceeded them.</strong>
          <br />
          <br />
          Even the first module is providing clarity on concepts I've
          used for years but never learned in detail. Mental models
          are essential, and I may finally have one for CSS.
        </TestimonialContent>
      </DoubleTestimonial>
      <Spacer $size={192} />
      <a id="purchase-anchor" />
      <ClientOnly>
        <PurchaseSection
          id={FIRST_PURCHASE_BLOCK_ID}
          totalPurchases={totalPurchases}
          productPricing={productPricing}
          enableRegionalDiscount={enableRegionalDiscount}
          toggleRegionalDiscount={toggleRegionalDiscount}
          productPricingStatus={productPricingStatus}
        />
      </ClientOnly>
      <Spacer $size={128} />
      <TeamLicenseBlurb />
      <Spacer $size={92} />
      <Testimonial
        authorName="Adam Wathan"
        authorTitle="Creator of Tailwind CSS"
        authorAvatar="/avatars/adamwathan.jpg"
        href="https://twitter.com/adamwathan"
        maxWidth={770}
      >
        What I love about the way Josh teaches is that he’ll do
        whatever it takes to make sure a concept is clearly understood
        and that you’re building an accurate and helpful mental model.{' '}
        <strong>
          Josh will literally invent new tools from scratch
        </strong>{' '}
        if it will help a concept stick, even if it’s 10x more work
        for him.
        <br />
        <br />
        When I’m learning something from Josh, I know it’s being
        taught the best way it possibly could be.{' '}
        <strong>
          There’s no person I’d trust more to really install CSS into
          my brain.
        </strong>
      </Testimonial>
      <AboutMe />
      <Spacer $size={64} />
      <DoubleTestimonial>
        <TestimonialContent
          authorName="Shawn “Swyx” Wang"
          authorTitle="Author, “The Coding Career Handbook”"
          authorAvatar="/avatars/swyx.jpg"
          href="https://twitter.com/swyx"
          type="double"
        >
          This is a complete exploration of all the CSS knowledge that
          modern frontend devs need to know,{' '}
          <strong>
            over a decade worth of knowledge packed in here
          </strong>{' '}
          with the same care and delight that Josh is famous for on
          his blog.
        </TestimonialContent>
        <TestimonialContent
          authorName="Monica Lent"
          authorTitle="Founder, Blogging for Devs"
          authorAvatar="/avatars/monicalent.jpg"
          href="https://twitter.com/monicalent"
          type="double"
        >
          Josh’s blog is pure gold if you want to learn CSS. This
          course has{' '}
          <strong>all the polish you’d expect from Josh</strong>, plus
          the structure to make sure you're getting the complete
          picture.
        </TestimonialContent>
      </DoubleTestimonial>
      <Spacer $size={64} />
      <Curriculum />
      <Spacer $size={64} />
      <section>
        <Testimonial
          authorName="Max Stoiber"
          authorTitle="Creator of styled-components"
          authorAvatar="/avatars/mxstbr.jpg"
          href="https://twitter.com/mxstbr"
        >
          Using CSS and knowing CSS are two very different things.
          I've been using CSS for years, but I didn't know it well.{' '}
          <strong>This course is awesome</strong> - I've already
          learned a ton, and I can't wait to keep going!
        </Testimonial>
      </section>
      <Spacer $size={128} />
      <ClientOnly>
        <PurchaseSection
          id="last-chance-block"
          totalPurchases={totalPurchases}
          productPricing={productPricing}
          enableRegionalDiscount={enableRegionalDiscount}
          toggleRegionalDiscount={toggleRegionalDiscount}
          productPricingStatus={productPricingStatus}
        />
        {/*
          Show a second email opt-in IF they're in the test group
          and UNLESS they've already joined through the first form.
        */}
        {isInTestGroup && joinedEmailCourseThroughForm !== 'hero' && (
          <LastChanceSignupForm
            handleSignup={() =>
              setJoinedEmailCourseThroughForm('last-chance')
            }
          />
        )}
      </ClientOnly>
      <Spacer $size={64} />
      <Testimonial
        authorName="Addy Osmani"
        authorTitle="Engineering Manager at Google"
        authorAvatar="/avatars/addyosmani.jpg"
        href="https://twitter.com/addyosmani"
        maxWidth={770}
      >
        <strong>
          Josh is one of the brightest authorities on CSS out there
        </strong>
        , bringing both deep technical insights and a fantastic amount
        of whimsy to all his work. I highly recommend checking his
        course out if you're looking to level up!
      </Testimonial>
      <Spacer $size={64} />
      <Faq />
      <Spacer $size={128} />
      <WallOfLove />
      <Spacer $size={128} />
      <section>
        <DoubleTestimonial>
          <TestimonialContent
            authorName="Randall Kanna"
            authorTitle="Author, “The Standout Developer”"
            authorAvatar="/avatars/randallkanna.jpg"
            href="https://twitter.com/RandallKanna"
            type="double"
          >
            This course will get you working with CSS right away, in a
            friendly and fun way. I’ve been an engineer for 6+ years
            and{' '}
            <strong>
              this is the css course I WISH I had when I was learning
              CSS!
            </strong>
          </TestimonialContent>
          <TestimonialContent
            authorName="David Elliott"
            authorTitle="Full-stack developer at Telus Digital"
            authorAvatar="/avatars/david-elliott.jpg"
            href="https://twitter.com/DStuartElliott/status/1312057879810187265?s=20"
            type="double"
          >
            Josh is basically{' '}
            <strong>
              the best teacher I have ever encountered online.
            </strong>{' '}
            And I manage a team of teachers for a living!
          </TestimonialContent>
        </DoubleTestimonial>
      </section>
      {/* <Divider maxWidth={800} /> */}
      <Spacer $size={32} />
      <Footer />
      <SEO title="CSS for JavaScript Developers | An online course that teaches the fundamentals of CSS for React/Vue devs" />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  max-width: 100%;
  /* This MAY not be needed now that I have <Clamped> */
  overflow: hidden;
`;

const Clamped = styled.div`
  /*
    When the spotlights in the title swoop to the side, don't allow
    them to overflow and add a horizontal scrollbar. Doing it here
    because I don't want to clip them vertically, nor do I want to
    apply 'overflow: hidden' to the body and break sticky
    positioning.
  */
  overflow: hidden;
`;

export default IndexPage;
