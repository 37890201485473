import React from 'react';
import styled from 'styled-components';

import { throttle } from '@utils';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import List from '@components/List';

function BonusList() {
  const listRef = React.useRef();

  const [position, setPosition] = React.useState('top');

  React.useEffect(() => {
    if (!listRef.current) {
      return;
    }

    const handleScroll = throttle(function (target) {
      if (target.scrollTop === 0) {
        setPosition('top');
      } else if (target.scrollTop < 400) {
        setPosition('middle');
      } else {
        setPosition('bottom');
      }
    }, 250);

    const handleScrollWrapper = (ev) =>
      handleScroll(ev.currentTarget);

    const listElem = listRef.current;

    listElem.addEventListener('scroll', handleScrollWrapper);

    return () => {
      listElem.removeEventListener('scroll', handleScrollWrapper);
    };
  }, []);

  return (
    <Wrapper>
      <PseudoTitle>And so much more…</PseudoTitle>
      <IntroParagraph>
        This curriculum barely scratches the surface. <DesktopBreak />
        Here are some other important things included in the course:
      </IntroParagraph>

      <ListContainingBlock>
        <ListWrapper ref={listRef}>
          <GridList>
            <List.ListItem>
              A <strong>Discord community</strong>, with an enforced
              Code of Conduct so that everyone feels welcome.
            </List.ListItem>
            <List.ListItem>
              A strong focus on <strong>accessibility</strong> —
              instead of an "accessibility module", it's weaved into{' '}
              <em>every</em> module.
            </List.ListItem>
            <List.ListItem>
              Learn how to <strong>structure our CSS at scale</strong>
              , avoiding the explosion of complexity that typically
              comes with large projects.
            </List.ListItem>
            <List.ListItem>
              Working with legacy browsers: how to support IE while
              still being able to write modern, enjoyable CSS.
            </List.ListItem>
            <List.ListItem>
              The fundamentals that will supercharge your ability to
              work with CSS frameworks like Tailwind.
            </List.ListItem>
            <List.ListItem>
              Building a design from a mockup using Figma (a popular,
              free-to-use design tool).
            </List.ListItem>
            <List.ListItem>
              How to use the browser devtools to squash CSS bugs
              effectively.
            </List.ListItem>
            <List.ListItem>
              <strong>No BEM or SMACSS</strong> — we'll see how modern
              tooling provides the same benefits but without the
              friction.
            </List.ListItem>
            <List.ListItem>
              Understanding hidden-but-vital mechanisms like stacking
              contexts, hypothetical sizes, and containing blocks.
            </List.ListItem>
            <List.ListItem>
              Building fluid, adaptive interfaces without media
              queries.
            </List.ListItem>
            <List.ListItem>
              How to use styled-components effectively.
            </List.ListItem>
            <List.ListItem>
              Using optical alignment to create next-level polished
              interfaces.
            </List.ListItem>
            <List.ListItem>
              The rich world of selectors, and how to use combined
              selectors for optimal results.
            </List.ListItem>
            <List.ListItem>
              How to write meaningful semantic HTML.
            </List.ListItem>
            <List.ListItem>
              Building beautiful and accessible form controls.
            </List.ListItem>
            <List.ListItem>
              <strong>Mini-games</strong> that help you build deep
              intuitions for complex mechanics.
            </List.ListItem>
            <List.ListItem>
              Everything you need to know about margins (there's a
              surprising amount!)
            </List.ListItem>
            <List.ListItem>
              Knowing when and how to use global styles in a
              component-focused application.
            </List.ListItem>
            <List.ListItem>
              Managing overflow text with an ellipsis, both
              single-line and multi-line.
            </List.ListItem>
            <List.ListItem>
              How to design animations that serve a purpose and don't
              annoy the user.
            </List.ListItem>
            <List.ListItem>
              Using CSS variables for color themes, dynamic
              calculations, and more.
            </List.ListItem>
            <List.ListItem>
              How to use web fonts responsibly, ensuring the best
              experience for all users.
            </List.ListItem>
            <List.ListItem>
              Learn bleeding-edge CSS features, and how to use them
              responsibly with progressive enhancement.
            </List.ListItem>
            <List.ListItem>
              How to make shadows that look and feel real, based on
              physics and the natural world.
            </List.ListItem>
          </GridList>
        </ListWrapper>
        <MobileShadow />
      </ListContainingBlock>
      <TopDesktopShadow
        style={{ opacity: position === 'top' ? 0 : 1 }}
      />
      <BottomDesktopShadow
        style={{ opacity: position === 'bottom' ? 0 : 1 }}
      />
    </Wrapper>
  );
}
const Wrapper = styled(MaxWidthWrapper)`
  max-width: 900px;
`;

// Making PseudoTitle an `em` instead of an `h2` since it really
// isn't a heading, it's just a big visually-emphasized bit of
// text.
const PseudoTitle = styled.em`
  display: block;
  margin-bottom: 16px;
  font-style: normal;
  font-size: min(48px, 9vw);
  font-weight: 500;
  text-align: center;
  background-image: linear-gradient(
    200.67deg,
    #ffffff 25%,
    hsl(54deg 100% 70%) 35%,
    hsl(36deg 100% 55%) 55.54%,
    hsl(333deg 100% 50%) 75%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  @media (max-width: 500px) {
    text-align: left;
  }
`;

const IntroParagraph = styled.p`
  font-size: 21px;
  text-align: center;
  margin-bottom: 64px;

  @media (max-width: 500px) {
    text-align: left;
  }
`;

const DesktopBreak = styled.br`
  @media (max-width: 500px) {
    display: none;
  }
`;

const ListContainingBlock = styled.div`
  position: relative;
  margin: 0 -32px;
`;

const ListWrapper = styled.div`
  padding: 0 32px 0 32px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;

  @media (max-width: 900px) {
    &:after {
      content: '';
      min-width: 100px;
      pointer-events: none;
    }
  }

  @media (min-width: 900px) {
    max-height: 500px;
    flex-direction: column;
  }
`;

const GridList = styled(List)`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px 32px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(9, 250px);
  }
`;

const MobileShadow = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  background: linear-gradient(
    to right,
    hsl(274deg 16% 8% / 0),
    hsl(274deg 16% 8% / 1)
  );
  pointer-events: none;

  @media (max-width: 900px) {
    display: block;
  }
`;

const DesktopShadow = styled.div`
  position: absolute;
  bottom: var(--bottom);
  left: 0;
  right: 15px;
  height: 100px;
  background: linear-gradient(
    to var(--gradient-direction),
    hsl(274deg 16% 8% / 0),
    hsl(274deg 16% 8% / 1)
  );
  transition: opacity 250ms;
  pointer-events: none;

  @media (max-width: 900px) {
    display: none;
  }
`;

const TopDesktopShadow = styled(DesktopShadow)`
  --bottom: 400px;
  --gradient-direction: top;
  opacity: 0;
`;
const BottomDesktopShadow = styled(DesktopShadow)`
  --bottom: 0;
  --gradient-direction: bottom;
`;

export default BonusList;
