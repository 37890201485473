import React from 'react';
import { useSpring } from 'react-spring';

import { TIGHT_SPRING } from '@constants';
import usePrefersReducedMotion from '@hooks/use-prefers-reduced-motion.hook';

function useBoop({
  x = 0,
  y = 0,
  rotation = 0,
  scale = 1,
  timing = 150,
  springConfig = TIGHT_SPRING,
  disableWillChange,
}) {
  const prefersReducedMotion = usePrefersReducedMotion();

  const [isBooped, setIsBooped] = React.useState(false);

  const springObject = {
    transform: isBooped
      ? `translate(${x}px, ${y}px)
       rotate(${rotation}deg)
       scale(${scale})`
      : `translate(0px, 0px)
       rotate(0deg)
       scale(1)`,
    config: springConfig,
  };

  if (!disableWillChange) {
    springObject.willChange = 'transform';
  }

  const style = useSpring(springObject);

  React.useEffect(() => {
    if (!isBooped) {
      return;
    }

    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped]);

  const trigger = React.useCallback(() => {
    setIsBooped(true);
  }, []);

  let appliedStyle = prefersReducedMotion ? {} : style;

  return [appliedStyle, trigger];
}

export default useBoop;
