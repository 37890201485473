import React from 'react';
import styled from 'styled-components';

import { COUNTRIES } from '@helpers/geography.helpers';
import Checkbox from '@components/Checkbox';

const RegionalDiscount = ({
  isEnabled,
  toggleIsEnabled,
  country,
  discountRate,
}) => {
  const countryLabel = COUNTRIES[country];

  return (
    <Wrapper>
      <p>
        <span role="img" aria-label="Hand waving">
          👋
        </span>{' '}
        Hey there! It looks like you're located in{' '}
        <strong>
          <Flag alt="" src={`/flags/${country}.svg`} />
          {countryLabel}
        </strong>
        . This is a premium course with a premium pricetag, but I
        don't want it to be prohibitively expensive.
      </p>
      <p>If you need it, you can activate regional pricing:</p>
      <Label>
        <Checkbox checked={isEnabled} onChange={toggleIsEnabled} />{' '}
        Activate {discountRate}% off
      </Label>
      <p>
        When purchasing with regional pricing enabled, you will only
        be able to access the course within {countryLabel}.
      </p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: hsl(223deg 10% 30% / 0.4);
  border: 2px dashed;
  padding: 32px;

  p:last-of-type {
    margin-bottom: 0px;
  }
`;

const Flag = styled.img`
  height: 0.75em;
  height: 1.5ex;
  margin-right: 6px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 1.65em;
  font-weight: bold;
  background: hsl(223deg 5% 40% / 0.2);
  padding: 12px 16px;
  border-radius: 4px;
`;

export default RegionalDiscount;
