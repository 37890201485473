import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  getTimeRemaining,
  getTimeRemainingInHumanTerms,
  getIsTwilightHour,
} from '@reducers/countdown.reducer';

import Count from './Count';

const LaunchBanner = (props) => {
  const timeRemainingRaw = useSelector(getTimeRemaining);
  const timeRemainingData = useSelector(getTimeRemainingInHumanTerms);
  const isTwilightHour = useSelector(getIsTwilightHour);

  if (timeRemainingRaw <= 0) {
    return null;
  }

  return (
    <OuterWrapper {...props}>
      <Wrapper>
        <Intro>
          {isTwilightHour ? (
            <TwilightMessage>Sale extended!</TwilightMessage>
          ) : (
            <strong>Black&nbsp;Friday&nbsp;Sale!</strong>
          )}{' '}
          Ends&nbsp;in:
        </Intro>
        <Counter>
          <Count unit="day" value={timeRemainingData.days} />
          <Count unit="hour" value={timeRemainingData.hours} />
          <Count unit="minute" value={timeRemainingData.minutes} />
          <Count unit="second" value={timeRemainingData.seconds} />
        </Counter>
      </Wrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled.div`
  /* border: 3px dashed black; */
  padding: 2px;
  border-radius: 5px;
`;

const Wrapper = styled.div`
  background: hsl(50deg 80% 90%);
  padding: 16px;
  border-radius: 2px;
`;

const Intro = styled.p`
  text-align: center;
`;

const Counter = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  max-width: 325px;
  margin: 0 auto;
  /* HACK: Avoid single-pixel height changes between numbers */
  min-height: 60px;
`;

const TwilightMessage = styled.strong`
  color: hsl(20deg 100% 35%);
`;

export default LaunchBanner;
