import React from 'react';
import styled from 'styled-components';

import RainbowButton from './RainbowButton';

const PoofyRainbowButton = (props) => {
  return (
    <Wrapper>
      <RainbowButton {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  transition: transform 175ms;
  will-change: transform;
  --grow-x: 1.02;
  --grow-y: 1.08;

  & span {
    transition: transform 175ms;
    will-change: transform;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 6px;
    right: 6px;
    height: 20%;
    background: linear-gradient(
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    border-radius: 20px;
    border-top-left-radius: 20px 14px;
    border-bottom-left-radius: 100px 30px;
    border-top-right-radius: 20px 14px;
    border-bottom-right-radius: 100px 30px;
    pointer-events: none;
    opacity: 0.25;
    transition: opacity 175ms;
  }

  &:hover::before {
    opacity: 0.35;
  }

  @media (hover: hover) {
    &:hover:not(:active) {
      transform: scaleX(var(--grow-x)) scaleY(var(--grow-y));
    }

    &:hover:not(:active) span {
      transform: scaleX(calc(1 / var(--grow-x)))
        scaleY(calc(1 / var(--grow-y)));
    }

    &:active,
    &:active span {
      transition: transform 0ms;
    }
  }
`;

export default PoofyRainbowButton;
