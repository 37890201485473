import React from 'react';
import styled from 'styled-components';

import { detectBrowser } from '@helpers/browser.helpers';

function PhotoEffect() {
  const browser = detectBrowser();

  return (
    <Wrapper>
      {browser !== 'safari' && (
        <PhotoCopy alt="" src="/josh-big-photo.jpg" />
      )}
      <BigPhoto
        src="/josh-big-photo.jpg"
        alt="The devilishly-handsome author of this course, sitting and smiling."
      />
      <Gradient />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  isolation: isolate;
  transform: translateX(-96px);
`;

const Gradient = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 500px;
  height: 500px;
  background: linear-gradient(
    to top,
    hsl(274deg 16% 8% / 0.75),
    hsl(274deg 16% 8% / 0) 50%
  );
  border-radius: 50%;

  @media (max-width: 500px) {
    width: 400px;
    height: 400px;
  }
`;

const BigPhoto = styled.img`
  position: relative;
  width: 500px;
  height: 500px;
  object-fit: cover;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 20px hsl(274deg 16% 8%));

  @media (max-width: 500px) {
    width: 400px;
    height: 400px;
  }
`;

const PhotoCopy = styled(BigPhoto)`
  position: absolute;
  filter: blur(150px);
  opacity: 0.6;
  transform: translateX(25%);
`;

export default PhotoEffect;
