import MaxWidthWrapper from '@components/MaxWidthWrapper';
import React from 'react';
import styled from 'styled-components';

const LOGOS = [
  {
    slug: 'apple',
    label: 'Apple',
    width: 45,
    translateY: -12,
  },
  {
    slug: 'google',
    label: 'Google',
    width: 130,
  },
  {
    slug: 'microsoft',
    label: 'Microsoft',
  },
  {
    slug: 'facebook',
    label: 'FACEBOOK',
    translateY: -5,
  },
  {
    slug: 'aws',
    label: 'Amazon Web Services',
    width: 75,
    translateY: 10,
  },
  {
    slug: 'netflix',
    label: 'Netflix',
    width: 130,
  },
  {
    slug: 'linkedin',
    label: 'LinkedIn',
  },
  {
    slug: 'adobe',
    label: 'Adobe',
    width: 130,
  },
  {
    slug: 'shopify',
    label: 'Shopify',
    width: 130,
    translateY: 3,
  },
  {
    slug: 'airbnb',
    label: 'Airbnb',
    width: 140,
  },
  {
    slug: 'spotify',
    label: 'Spotify',
    width: 140,
  },
  {
    slug: 'new-york-times',
    label: 'The New York Times',
    width: 165,
    translateY: 5,
  },
  {
    slug: 'paypal',
    label: 'PayPal',
    width: 140,
  },
  {
    slug: 'automattic',
    label: 'Automattic (WordPress)',
    translateY: 3,
  },
  {
    slug: 'dropbox',
    label: 'Dropbox',
  },
  {
    slug: 'atlassian',
    label: 'Atlassian',
    width: 165,
  },
  // {
  //   slug: 'lyft',
  //   label: 'Lyft',
  //   width: 60,
  //   translateY: -2,
  // },
];

const CompanyLogos = () => {
  return (
    <Wrapper maxWidth={1000}>
      <Title>Trusted by developers at</Title>
      <Grid>
        {LOGOS.map(({ slug, label, width, translateY = 0 }) => (
          <Cell key={slug}>
            <Image
              alt={`${label} logo`}
              src={`/logos/${slug}.svg`}
              style={{
                width,
                transform: `translateY(${translateY}px)`,
              }}
            />
          </Cell>
        ))}
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled(MaxWidthWrapper)``;

const Title = styled.h2`
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  color: var(--color-gray-700);
  margin-bottom: 32px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`;

const Cell = styled.div`
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /*
    On mobile, it collapses into a single column. This means
    we wind up showing A LOT of logos. So let's truncate the list.
  */
  @media (max-width: 450px) {
    height: 100px;

    &:nth-of-type(n + 11) {
      display: none;
    }
  }
  @media (max-width: 1000px) {
    &:nth-of-type(n + 16) {
      display: none;
    }
  }
`;

const Image = styled.img`
  display: block;
  width: 150px;
  opacity: 0.5;
`;

const Label = styled.div`
  text-align: center;
  font-size: 0.875rem;
  color: var(--color-gray-500);
`;

export default CompanyLogos;
